import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name:faker.commerce.price(),
  company: faker.commerce.price(),
  isVerified: faker.commerce.price(),
  status: sample(['active', 'banned']),
  role: faker.commerce.price(),
}));

export default users;
