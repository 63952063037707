import { Box } from '@mui/system'
 
import ReportTable from "./reportTable"
import supabase from 'src/supabase.config'
 
import { TextField,Button } from '@mui/material'
import { CreateNewFolder, DateRange, NewReleases } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { updateReportDate } from 'src/redux/slice'


import React, { Fragment,useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = yyyy + '-' + mm + '-' + dd;
const Container = () => {

  const [selectedDate, handleDateChange] = useState(new Date());
  const [date, changeDate] = useState(new Date());
  const dispatch=useDispatch()
 


// const [Date, setDate] = useState(today)

 

useEffect(() => {
  dispatch(updateReportDate(selectedDate.getFullYear()+"-"+(selectedDate.getMonth()+1)+"-"+selectedDate.getDate()))
  console.log(selectedDate.getFullYear()+"-"+(selectedDate.getMonth()+1)+"-"+selectedDate.getDate())
},[selectedDate,dispatch]);

  return (
<Box sx={{direction:'rtl'}}>

<Box sx={{width:'50%',height:'auto',backgroundColor:' ',fontSize:"18px",pr:5,fontWeight:'bold'}}>
ملخص الصناديق

</Box>
<Box sx={{width:'100%',pr:"200px",height:"200px"}}>
{/* <DatePicker
        
        
        format="yyyy-MM-dd"
        label="Date of birth"
         
        value={selectedDate}
        onChange={handleDateChange}
      /> */}
</Box>

<ReportTable Date={Date}  />


</Box>
  )
}

export default Container