import { Box, Button, Chip, Icon } from '@mui/material'
import React from 'react'
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import supabase from 'src/supabase.config';

import {useState} from "react"

const Open = () => {
  var d = new Date(); // for now
  const Time = d.getHours() - 1 + ':' + d.getMinutes() + ':' + d.getSeconds()
  const [isOpen, setisOpen] = useState(false)
  const Open_caisse = async () => {


    const { data, error } = await supabase
      .from('caisse')
      .update({ Timedata: Time, open: true })
      .eq('id', 1)
    if (data) {
      console.log(data)
      localStorage.setItem('Open_Time', Time)
    }
  }




  return (
    <Box sx={{ width: '100%', height: '500px', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
      <Chip label='الساعة' />
      <Chip label='التاريخ' />
      {!isOpen ? <Button variant="contained" onClick={Open_caisse}>
        فتح الصندوق
        <AlarmOnIcon />
      </Button>
        : "الصندوق مفتوح"

      }
    </Box>
  )
}

export default Open