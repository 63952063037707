import React,{useState,useEffect} from 'react'

// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'


//Redux 
import { useDispatch, useSelector } from 'react-redux'
import { newChanging, newSpend } from 'src/redux/slice';


//Libs
import currencyFormatter from "currency-formatter"


//Detial Modal
import DetailModal from "../reporting/infoModal"

const DetailsForm = () => {
  const dispatch = useDispatch()
  const Changing_Data = useSelector((state) => state.caisse.new_changing)
  const NewSpend_Data = useSelector((state)=>state.caisse.new_spend)
  const [B2000, setB2000] = useState(Changing_Data.B2000_out)
  const [paperSold, setpaperSold] = useState(0)
const [coinSOld, setcoinSOld] = useState(0)

  const handlenewChanging = (e) => {
    switch (e.target.id) {

      case 'B2000':
        setB2000(e.target.value)
        dispatch(newChanging({ key: 'B2000_out', value: e.target.value }))
        dispatch(newSpend({ key: 'B2000', value: Number(e.target.value)}))
      
        break;
      case 'B1000':
        dispatch(newChanging({ key: 'B1000_out', value: e.target.value }))
        dispatch(newSpend({ key: 'B1000', value:Number(e.target.value) }))
        break;
      case 'B500':
        dispatch(newChanging({ key: 'B500_out', value: e.target.value }))
        dispatch(newSpend({ key: 'B500', value: Number(e.target.value) }))
        break;
      case 'B200':
        dispatch(newChanging({ key: 'B200_out', value: e.target.value }))
        dispatch(newSpend({ key: 'B200', value: Number(e.target.value) }))
        break;
      case 'C200':
        dispatch(newChanging({ key: 'C200_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C200', value: Number(e.target.value) }))
        break;
      case 'C100':
        dispatch(newChanging({ key: 'C100_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C100', value: Number(e.target.value) }))
        break;
      case 'C50':
        dispatch(newChanging({ key: 'C50_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C50', value: Number(e.target.value) }))
        break;
      case 'C20':
        dispatch(newChanging({ key: 'C20_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C20', value:Number(e.target.value) }))
        break;
      case 'C10':
        dispatch(newChanging({ key: 'C10_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C10', value:Number(e.target.value) }))
        break;
      case 'C5':
        dispatch(newChanging({ key: 'C5_out', value: e.target.value }))
        dispatch(newSpend({ key: 'C5', value: Number(e.target.value) }))
        break;
      case 'sold':
        dispatch(newChanging({ key: 'sold', value: 3000 }))
        dispatch(newSpend({ key: 'sold', value: 3000 }))
        break;

    }
    console.log(NewSpend_Data)
  }
  useEffect(() => {
    

    setpaperSold(Changing_Data.B2000_out*2000+Changing_Data.B1000_out*1000+Changing_Data.B500_out*500+Changing_Data.B200_out*200)
    setcoinSOld(Changing_Data.C200_out*200+Changing_Data.C100_out*100+Changing_Data.C50_out*50+Changing_Data.C20_out*20+Changing_Data.C10_out*10+Changing_Data.C5_out*5)
  });
  const [enterkeycount, setenterkeycount] = useState(0)
  const B2000Ref1 = React.useRef();
    const B1000Ref2 = React.useRef();
    const B500Ref3 = React.useRef();
    const B200Ref4 = React.useRef();

    const C200Ref = React.useRef();
    const C100Ref = React.useRef();
    const C50Ref = React.useRef();
    const C20Ref = React.useRef();
  
    const C10Ref = React.useRef();
    const C5Ref = React.useRef();
    const incerment_enter_key = (e) => {

      if (e.key === "Enter") {
        setenterkeycount(enterkeycount + 1)
      }
    }
    useEffect(() => {
      switch (enterkeycount) {
        case 1:
          // code block
          B2000Ref1.current.focus();
           
         
          break;
        case 2:
          // code block
          B1000Ref2.current.focus();
           
          break;
  
        case 3:
          // code block
          B500Ref3.current.focus();
  
       
          break;
        case 4:
          // code block
          B200Ref4.current.focus();
          
          break;
       
  
        case 5:
          // code block
  
  
          C200Ref.current.focus();
          break;
        case 6:
          // code block
          C100Ref.current.focus();
          break;
        case 7:
          // code block
          C50Ref.current.focus();
          break;
        case 8:
          // code block
          C20Ref.current.focus();
          break;
        case 9:
          // code block
          C10Ref.current.focus();
          break;
        case 10:
          // code block
          C5Ref.current.focus();
          break;
        case 11:
          // code block
          setenterkeycount(0)
  
          break;
         
  
        // code block
      }
    }, [enterkeycount]);
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }} onKeyPress={(e) => incerment_enter_key(e)}>
     <Chip sx={{ mb: 4, backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ الورقي  "+currencyFormatter.format(paperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
              <DetailModal id={Changing_Data.caisse}/>
               <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="B2000" label="  2000 دج" value={Changing_Data.B2000_out} inputRef={B2000Ref1} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B1000" label="1000 دج" value={Changing_Data.B1000_out}  inputRef={B1000Ref2} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B500" label="500 دج" value={Changing_Data.B500_out} inputRef={B500Ref3} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B200" label="200 دج" value={Changing_Data.B200_out} inputRef={B200Ref4} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>

      </Grid>
      <br></br>
      <Chip sx={{ mb: 4, backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ المعدني  "+currencyFormatter.format(coinSOld, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} /> <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C200" label="200 دج" value={Changing_Data.C200_out}  inputRef={C200Ref}  variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C100" label="100 دج"  value={Changing_Data.C100_out}  inputRef={C100Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>

        <Grid item xs={3}>
          <TextField id="C50" label="50 دج" value={Changing_Data.C50_out}   inputRef={C50Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C20" label="20 دج" value={Changing_Data.C20_out}  inputRef={C20Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C10" label="10 دج" value={Changing_Data.C10_out}  inputRef={C10Ref}  variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C5" label="5 دج" value={Changing_Data.C5_out} inputRef={C5Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>


      </Grid>
    </Box>
  )
}

export default DetailsForm