// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'تقارير',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'تغدية الصناديق',
    path: '/dashboard/income_v2',
    icon: getIcon('eva:file-add-fill'),
  },
  {
    title: 'دفع الفواتير ',
    path: '/dashboard/payment',
    icon: getIcon('eva:file-remove-fill'),
  }, 
  {
    title: 'تحويل بين الصناديق',
    path: '/dashboard/transfer',
    icon: getIcon('eva:flip-2-fill'),
  },
  {
    title: 'تبديل العملات',
    path: '/dashboard/changing',
    icon: getIcon('eva:flip-outline'),
  },
  {
    title: 'باقي عملية الدفع',
    path: '/dashboard/rest',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'تغدية الحساب البنكي',
    path: '/dashboard/aliment_bank',
    icon: getIcon('eva:briefcase-fill'),
  },
  {
    title: ' كشف الصناديق',
    path: '/dashboard/report',
    icon: getIcon('eva:calendar-fill'),
  },
  {
    title: '   محاضر الصناديق',
    path: '/dashboard/Proceeding',
    icon: getIcon('eva:folder-remove-fill'),
  },
  {
    title: ' أرشيف الصناديق',
    path: '/dashboard/operation_In_Out',
    icon: getIcon('eva:clipboard-fill'),
  },
  {
    title: ' خروج',
    path: '/login',
    icon: getIcon('eva:log-out-outline'),
  },
];

export default navConfig;
