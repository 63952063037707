import { eq } from "lodash"
import supabase from "src/supabase.config"



export const Authentication=async(email,password)=>{
    let { data: users, error } = await supabase
    .from('users')
    .select('*')
    .eq("email",email)
    .eq("Password",password)
    if(users.length>0){
        return users
    }
  
}

