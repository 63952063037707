import React from 'react'


// Material Ui
import { Grid, Box, TextField,Chip } from '@mui/material'


const infoForm = () => {
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
<Chip sx={{mb:5}} label="   الصندوق   MDD"    />
      <Grid container spacing={1}>

        <Grid item xs={6}>
        
<Chip label='  التاريخ 12-01-2022      '/>
        </Grid>
        <Grid item xs={6}>
        <Chip label=' الساعة  12:01:01      '/>
        
        </Grid>


      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={6}>
          <TextField id="outlined-basic" label="المبلغ المحول" variant="outlined" />

        </Grid>
        <Grid item xs={6}>
          <TextField id="outlined-basic" label="المكلف" variant="outlined" />

        </Grid>


      </Grid>
    </Box>
  )
}

export default infoForm