import supabase from "src/supabase.config";



// const handlenewTransfer = (e) => {
//     switch(e.target.id) {

//         case 'active':
//             dispatch(newTransfer({ key: 'B2000', value: e.target.value }))
//         break;
//         case 'active':
//         //background green
//         break;
//         case 'active':
//         //background green
//         break;
//         case 'active':
//         //background green
//         break;
//         case 'active':
//         //background green
//         break;
//         case 'active':
//         //background green
//         break;
//         case 'active':
//         //background green
//         break;
//     }
//     if (e.target.id == 'B2000') {
//       dispatch(newTransfer({ key: 'B2000', value: e.target.value }))
//     }
//     if (e.target.id == 'taxpayer') {
//       dispatch(newTransfer({ key: 'taxpayer', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_out') {
//       dispatch(newTransfer({ key: 'caisse_out', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_in') {
//       dispatch(newTransfer({ key: 'caisse_in', value: e.target.value }))
//     }
//     if (e.target.id == 'sold') {
//       dispatch(newTransfer({ key: 'sold', value: e.target.value }))
//     }
//     if (e.target.id == 'taxpayer') {
//       dispatch(newTransfer({ key: 'taxpayer', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_out') {
//       dispatch(newTransfer({ key: 'caisse_out', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_in') {
//       dispatch(newTransfer({ key: 'caisse_in', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_out') {
//       dispatch(newTransfer({ key: 'caisse_out', value: e.target.value }))
//     }
//     if (e.target.id == 'caisse_in') {
//       dispatch(newTransfer({ key: 'caisse_in', value: e.target.value }))
//     }
//     console.log(Transfer_Data)
//   }




  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  
  export const todaydate=mm + '-' + dd + '-' + yyyy

  //export const todaydate="02-12-2022"




export const get_income=async(income_id)=>{
  
let { data: incomes_coffre, error } = await supabase
.from('incomes_coffre')
.select('*')
.eq('id',income_id)
if(incomes_coffre)return incomes_coffre

}



export const get_spend=async(spend_id)=>{

  let { data: spends_coffre, error } = await supabase
  .from('spends_coffre')
  .select('*')
  if(spends_coffre) return spends_coffre
}


export const Edit_income=async(income_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
  let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
  let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
const { data:incomes_coffre, error } = await supabase
.from('incomes_coffre')
.update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
.eq('id', income_id)
.select()
if(incomes_coffre)return incomes_coffre
if(error)console.log(error)

}