
import * as React from 'react';
import { useState, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, TextField } from '@mui/material';
import { get_operation } from './utils';
import supabase from 'src/supabase.config'; 

 

export default function NewOperation() {
    const [addnew, setaddnew] = useState(false)
    const [operations, setoperations] = useState([])
    const [operation_type, setoperation_type] = useState("")
    const saveOperation = async () => {

        const { data, error } = await supabase
            .from('OperationType')
            .insert([
                { Text: operation_type,caisse:1 },
            ])
            .select()
            if(data) setaddnew(false)
            if(error)console.log(error)

       

    }



    useEffect(() => {
        get_operation().then(res => {
            setoperations(res)
        })

    }, [])

    return (
        <TableContainer component={Paper} dir="rtl">
            <Box width="100%" height="auto" bgcolor={""}>

                <Button onClick={() => setaddnew(true)}>
             اضافة عملية
                </Button>
                {addnew ? <Box width="100%" height="50px" bgcolor={""} display="flex" >

                   

                    <TextField id="outlined-basic" label="عملية" onChange={(e)=>setoperation_type(e.target.value)} variant="outlined" />
                    
                    <Button sx={{mr:'22px',backgroundColor:'blue' ,color:"white"}} onClick={() => saveOperation()}>
                        حفظ
                    </Button>

                </Box> : ""}
            </Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>


                    </TableRow>
                </TableHead>
                <TableBody dir="rtl">
                    {operations?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.Text}
                            </TableCell>
                            <TableCell align="right">{row.Text}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
