import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import supabase from 'src/supabase.config';
// Resuable Components
import Menuaction from "./menuaction"
import Caisse from "../../components/caisse"


import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// Libs
import currencyFormatter from "currency-formatter"


// Redux
import { useSelector } from 'react-redux';
import { Box, Chip, Pagination } from '@mui/material';
import { ArrowDropDown, ArrowDropDownOutlined, ArrowDropDownSharp } from '@mui/icons-material';






const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2979ff",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CustomizedTables() {

  const SearcheActive = useSelector((state) => state.caisse.SearcheActive)

  const FilterData = useSelector((state) => state.caisse.FilterData)

  const [Changing_Data, setChanging_Data] = useState([])
  const [start, setstart] = useState(0)
  const [end, setend] = useState(4)

  const handlPagination = (page) => {
    setstart(page * 4)
    setend(page * 4 + 4)
  }


  useEffect(() => {
    const get_Changing_data = async () => {
      let { data: changement, error } = await supabase
        .from('coffre_history')
        .select("*")
        .range(start, end)
        .order('id', { ascending: false })
      if (changement) {
        console.log(changement)
        if (SearcheActive == true) setChanging_Data(FilterData)
        else setChanging_Data(changement)

      }
    }
    get_Changing_data()


    supabase
      .channel('public:coffre_history')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'coffre_history' }, payload => {
        console.log('Change received!', payload)
        //Leaten if data inserted update the table
        get_Changing_data()
      })
      .subscribe()




  }, [SearcheActive, FilterData, start]);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead sx={{ backgroundColor: "blue" }}>
          <TableRow>

            <StyledTableCell align="center">التاريخ </StyledTableCell>
            <StyledTableCell align="center"> الساعة</StyledTableCell>
            <StyledTableCell align="center"> الرصيد السابق</StyledTableCell>
            <StyledTableCell align="center"> الرصيد الجديد</StyledTableCell>

            <StyledTableCell align="center"> مبلغ العملية </StyledTableCell>
            <StyledTableCell align="center"> الصندوق</StyledTableCell>
            <StyledTableCell align="center"> نوع العملية</StyledTableCell>
            <StyledTableCell align="center"> المكلف</StyledTableCell>
            <StyledTableCell align="center"> اجراءات</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Changing_Data?.map((row) => (
            <StyledTableRow key={row?.id}>

              <StyledTableCell align="center">{row?.created_at?.split(':')[0]?.split('T')[0]}</StyledTableCell>
              <StyledTableCell align="center">{row?.created_at?.split('T')[1]?.split('.')[0]}</StyledTableCell>
              <StyledTableCell align="center">{row?.PrevSold}</StyledTableCell>
              <StyledTableCell align="center">{row?.NewSold}</StyledTableCell>


              <StyledTableCell align="center">
                <Chip label={
                  currencyFormatter.format(row?.sold, {
                    symbol: "دج",
                    decimal: ",",
                    thousand: ".",
                    precision: 2,
                    format: "%v %s", // %s is the symbol and %v is the value
                  })
                } />
              </StyledTableCell>

              <StyledTableCell align="center"><Caisse id={row?.caisse} /></StyledTableCell>

              <StyledTableCell align="center">



                {
                  (row.Type==="OUT") ? <>
                    <Chip label={

                      <>
                        OUT <ArrowDownwardIcon sx={{ color: "tomato" }} />
                      </>
                    } />

                  </>

                    :

                    <Chip label={

                      <>
                        IN <ArrowUpwardIcon sx={{ color: "yellowgreen" }} />
                      </>} />





                }
              </StyledTableCell>

              <StyledTableCell align="center">{row?.taxpayer}</StyledTableCell>

              <StyledTableCell align="center"><Menuaction data={row} /></StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ mt: 5, height: '40px', width: '100%', display: "flex", justifyContent: 'center', alingItems: 'center' }}>
        <Pagination onClick={(e) => handlPagination(e.target.textContent)} showFirstButton='true' sx={{ direction: 'ltr' }} count={10} variant="outlined" shape="rounded" />

      </Box>
    </TableContainer>
  );
}
