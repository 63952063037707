import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import supabase from "../../supabase.config"


//React 
import React, { useState, useEffect } from 'react';


//Redux
import {updateDataTime} from "../../redux/slice"



// mock



// Resuable Components
import Menuaction from "./menuaction"
import MenuPopover from 'src/components/MenuPopover';
import AlimentTable from "./alimentTable"
import NewModal from "./newModal"
import SearchFilter from "./searchFIlter"
import { useDispatch } from 'react-redux';


export default function Change_coffre() {
  const dispatch=useDispatch()
  const [Time_Data, setTime_Data] = useState('')
  const getcustomer = async () => {
    const { data, error } = await supabase
      .from('customer')
      .select().then((value) => {
        console.log(value.data);
        // expected output: "Success!"
      });

  }

  useEffect(() => {
    const get_Data_Time=async()=>{
      let { data: caisse, error } = await supabase
      .from('caisse')
      .select('Timedata')
      .eq('id',1)
      if(caisse)
      {
        dispatch(updateDataTime(caisse[0].Timedata))
      }
    }
    get_Data_Time()
  },[dispatch]);
  return (
    <Page title="Changement Coffre">
      <Container sx={{ direction: "rtl" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ color: 'yellowgreen' }}>
            تغدية الحساب البنكي
          </Typography>


          <NewModal />
        </Stack>
        <SearchFilter />
        <Box sx={{ width: '100%', height: '50px', display: 'flex', justifyContent: "space-between" }}>
          <Chip sx={{ backgroundColor: "#6495ED", color: "white" }} label='عدد العمليات 32' />

          <Chip sx={{ backgroundColor: "yellowgreen", color: "white" }} label='   مبلغ المداخيل 32' />


        </Box>
        <Card>

          <Scrollbar>
            <AlimentTable />
          </Scrollbar>


        </Card>
      </Container>
    </Page>
  );
}
