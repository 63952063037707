import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InfoTable from "./infoTable"

import supabase from 'src/supabase.config'
import { useState, useEffect } from 'react'
import { Print } from '@mui/icons-material';

import PrintTotal from "./printtotal"

import DetailTable from "./detailTable"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "650px",
  height: "500px",
  bgcolor: 'white',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  direction:"rtl"
};

export default function BasicModal({ id ,data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  return (
    <div>

      <Button onClick={handleOpen} sx={{ backgroundColor: "white", color: "blue" }} variant="contained">

        تقرير
        <AssessmentIcon sx={{ color: 'yellowgreen' }} /></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>


          {/* <InfoTable id={id} /> */}
<DetailTable    id={id}  />
<br></br>
          {/* <Button sx={{backgroundColor:"white",color:"blue"}} variant= "contained">
            طباعة     
            <Print sx={{mr:1}} />
          </Button> */}
          <PrintTotal  id={id} />
        </Box>
      </Modal>
    </div>
  );
}
