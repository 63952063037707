import * as React from 'react';
import { useEffect, useState } from 'react'






import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import supabase from 'src/supabase.config';

import currencyFormatter from "currency-formatter"
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, FormControl, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
const columns = [
  { id: 'type', label: 'الفئة', minWidth: 170 },
  { id: 'qty', label: 'العدد', minWidth: 100 },
  {
    id: 'sold',
    label: 'القيمة',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },

];

function createData(type, qty, number) {
  const sold = currencyFormatter.format(number * qty, {
    symbol: "دج",
    decimal: ",",
    thousand: ".",
    precision: 2,
    format: "%v %s", // %s is the symbol and %v is the value
  });


  return { type, qty, number, sold }
};



export default function StickyHeadTable({ id }) {
  const Report_Date=useSelector(state=>state.caisse.reportDate)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;




  const [detils_Data, setdetils_Data] = useState()
  //Income
  var TotalB2000 = 0, TotalB1000 = 0, TotalB500 = 0, TotalB200 = 0
  var TotalC200 = 0, TotalC100 = 0, TotalC50 = 0, TotalC20 = 0, TotalC10 = 0, TotalC5 = 0
  //Spend
  var TotalSpend_B2000 = 0, TotalSpend_B1000 = 0, TotalSpend_B500 = 0, TotalSpend_B200 = 0
  var TotalSpend_C200 = 0, TotalSpend_C100 = 0, TotalSpend_C50 = 0, TotalSpend_C20 = 0, TotalSpend_C10 = 0, TotalSpend_C5 = 0
  var Details;
  var SpendsSold = 0
  var IncomeSold = 0
  var Balance = 0;
  useEffect(() => {

    const get_details = async () => {



      let { data: incomes_coffre, error } = await supabase
        .from('incomes_coffre')
        .select('sold,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
        .eq('caisse', id)
        // .filter('Date', 'eq', Report_Date)
      if (incomes_coffre) {
        console.log('hey filter')
        console.log(incomes_coffre)
        for (let i = 0; i < incomes_coffre.length; i++) {
          IncomeSold = IncomeSold + incomes_coffre[i].sold
          TotalB2000 = incomes_coffre[i].B2000 + TotalB2000
          TotalB1000 = TotalB1000 + incomes_coffre[i].B1000
          TotalB500 = TotalB500 + incomes_coffre[i].B500
          TotalB200 = TotalB200 + incomes_coffre[i].B200


          TotalC200 = TotalC200 + incomes_coffre[i].C200
          TotalC100 = TotalC100 + incomes_coffre[i].C100
          TotalC50 = TotalC50 + incomes_coffre[i].C50
          TotalC20 = TotalC20 + incomes_coffre[i].C20
          TotalC10 = TotalC10 + incomes_coffre[i].C10
          TotalC5 = TotalC5 + incomes_coffre[i].C5

        }
        console.log('i am total 2000 Income')
        console.log(TotalB2000)
      }



      let { data: spends_coffre, errorspend } = await supabase
        .from('spends_coffre')
        .select('sold,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
        .eq('caisse', id)
        // .filter('Date', 'eq', Report_Date)
      if (spends_coffre) {
        console.log('hey spends_coffre')
        console.log(spends_coffre)
        for (let i = 0; i < spends_coffre.length; i++) {
          SpendsSold = SpendsSold + spends_coffre[i].sold
          TotalSpend_B2000 = spends_coffre[i].B2000 + TotalSpend_B2000
          TotalSpend_B1000 = TotalSpend_B1000 + spends_coffre[i].B1000
          TotalSpend_B500 = TotalSpend_B500 + spends_coffre[i].B500
          TotalSpend_B200 = TotalSpend_B200 + spends_coffre[i].B200


          TotalSpend_C200 = TotalSpend_C200 + spends_coffre[i].C200
          TotalSpend_C100 = TotalSpend_C100 + spends_coffre[i].C100
          TotalSpend_C50 = TotalSpend_C50 + spends_coffre[i].C50
          TotalSpend_C20 = TotalSpend_C20 + spends_coffre[i].C20
          TotalSpend_C10 = TotalSpend_C10 + spends_coffre[i].C10
          TotalSpend_C5 = TotalSpend_C5 + spends_coffre[i].C5


        }
        console.log('i am total 2000 Spend')
        console.log(TotalSpend_B2000)
      }


      setdetils_Data({
        TotalB2000: Number(TotalB2000 - TotalSpend_B2000),
        TotalB1000: Number(TotalB1000 - TotalSpend_B1000),
        TotalB500: Number(TotalB500 - TotalSpend_B500),
        TotalB200: Number(TotalB200 - TotalSpend_B200),

        TotalC200: Number(TotalC200 - TotalSpend_C200),
        TotalC100: Number(TotalC100 - TotalSpend_C100),
        TotalC50: Number(TotalC50 - TotalSpend_C50),
        TotalC20: Number(TotalC20 - TotalSpend_C20),
        TotalC10: Number(TotalC10 - TotalSpend_C10),
        TotalC5: Number(TotalC5 - TotalSpend_C5),
        Balance: Number(IncomeSold - SpendsSold),
      })
    }
    get_details()
  }, []);

  const rows = [
    createData('ورق 2000 دج', detils_Data?.TotalB2000, 2000),
    createData('ورق 1000 دج', detils_Data?.TotalB1000, 1000),
    createData('ورق 500 دج', detils_Data?.TotalB500, 500),
    createData('    ورق 200 دج   ', detils_Data?.TotalB200, 200),
    createData('معدن 200 دج', Math.abs(detils_Data?.TotalC200), 200),
    createData('معدن  100 دج', Math.abs(detils_Data?.TotalC100), 100),
    createData('معدن 50 دج', Math.abs(detils_Data?.TotalC50), 50),
    createData('معدن 20 دج', Math.abs(detils_Data?.TotalC20), 20),
    createData('معدن 10 دج', Math.abs(detils_Data?.TotalC10), 10),
    createData('معدن 5دج', Math.abs(detils_Data?.TotalC5), 5),
  ];
  console.log(detils_Data)
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', direction: 'rtl' }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

      </TableContainer>

      <Box display={"flex"}>

    

      <FormControl  sx={{  }}>
          <InputLabel htmlFor="outlined-adornment-amount"> الرصيد الورقي</InputLabel>
          <OutlinedInput
           sx={{width:'160px'}}
            id="outlined-adornment-amount"
          value={currencyFormatter.format(detils_Data?.TotalB2000 * 2000 + detils_Data?.TotalB1000 * 1000
            + detils_Data?.TotalB500 * 500 + detils_Data?.TotalB200 * 200, {
            symbol: "دج",
            decimal: ",",
            thousand: ".",
            precision: 2,
            format: "%v %s", // %s is the symbol and %v is the value
          })}
          
            label="Amount"
          />
        </FormControl>

        <FormControl  sx={{ }}>
          <InputLabel htmlFor="outlined-adornment-amount"> الرصيد المعدني</InputLabel>
          <OutlinedInput
           sx={{width:'160px',ml: 1,mr:1}}
            id="outlined-adornment-amount"
          value={currencyFormatter.format(detils_Data?.TotalC200 * 200 + detils_Data?.TotalC100 * 100 + detils_Data?.TotalC50 * 50 + detils_Data?.TotalC20 * 20
            + detils_Data?.TotalC10 * 10 + detils_Data?.TotalC5 * 5, {
            symbol: "دج",
            decimal: ",",
            thousand: ".",
            precision: 2,
            format: "%v %s", // %s is the symbol and %v is the value
          })}
          
           
            label="Amount"
          />
        </FormControl>
        <FormControl  sx={{ m: 1}}>
          <InputLabel htmlFor="outlined-adornment-amount"> الرصيد الاجمالي</InputLabel>
          <OutlinedInput
          sx={{width:'160px'}}
            id="outlined-adornment-amount"
            value={currencyFormatter.format(detils_Data?.Balance, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            })}
          
            label="Amount"
          />
        </FormControl>



        </Box>
    </Paper>
  );
}
