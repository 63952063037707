import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import supabase from 'src/supabase.config';
import { Authentication } from './Authutils';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
const [email, setemail] = useState("")
const [password, setpassword] = useState("")
 

  const onSubmit = async () => {

    Authentication(email,password).then(res=>{
      console.log("coffre id")
      console.log(res)
      localStorage.setItem("coffre_id",res[0]?.caisse)
      localStorage.setItem("user_type",res[0]?.type)
      navigate('/dashboard/app', { replace: true });
    })
    

    

  };

  return (
   <>
      <Stack spacing={3}>
        
        <TextField name="email" label="Email address" value={email} onChange={(e)=>setemail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          onChange={(e)=>setpassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

    

      <Button sx={{marginTop:"20px"}} fullWidth size="large" type="submit" variant="contained" onClick={onSubmit}  >
        تسجيل الدخول
      </Button>
      </>
  );
}
