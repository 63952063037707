import { Chip } from '@mui/material'
import React from 'react'

const CaisseComponent = (caisse) => {
    return (

        <Chip sx={{color:"blue"}} label={caisse == 1 ? "IFRI" : caisse == 2 ? "MMD" : caisse == 1 ? "MMG" : ""} />
    )
}

export default CaisseComponent

