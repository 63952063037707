import { CheckIcon } from '@chakra-ui/icons'
import { Box, Button } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import B1 from "./Icons/B1.png"
import B2 from "./Icons/B2.png"
import B3 from "./Icons/B3.png"
import B4 from "./Icons/B4.png"
import B5 from "./Icons/B5.png"
import B6 from "./Icons/B6.png"
import B7 from "./Icons/B7.png"
import PopoverInigma from './PopoverInigma'



const InigmaMenu = () => {
    const activeInigmaMenu=useSelector(state=>state.caisse.activeInigmaMenu)

    return (
        <Box sx={{ color: "white", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", paddingLeft: "2px", paddingRight: "2px", borderRadius: 1, boxShadow: 2, width: '120px', height: '400px', backgroundColor: "#253986", marginBlock: 10 }}>



            {
                [{ icon: B6 }, { icon: B5, backgroundColor: "white", open: (activeInigmaMenu===1)?true:false,name:1 }, { icon: B4, backgroundColor: "white", open: (activeInigmaMenu===2)?true:false,name:2 }, { icon: B3, backgroundColor: "white", open: (activeInigmaMenu===3)?true:false,name:3 }, { icon: B2, backgroundColor: "white", open: (activeInigmaMenu===4)?true:false,name:4 }].map(item => {
                    return (
                        <PopoverInigma icon={item?.icon} bgc={item.backgroundColor} active={item?.open} name={item?.name} />
                    )
                })
            }

        </Box>
    )
}

export default InigmaMenu