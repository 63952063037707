import React from 'react'


// Material Ui
import { Grid, Box, TextField, Chip, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { newIncome } from 'src/redux/slice'
import { useDispatch, useSelector } from 'react-redux'
import CoffreUtil from '../CoffreUtil'
import { SimpleGrid } from '@chakra-ui/react'

const InfoForm = () => {
  const dispatch = useDispatch()
  const [caisse, setCaisse] = React.useState(0)
  const newIncome_Data=useSelector(state=>state.caisse.new_income)
  const handlenewIncome = async (e) => {
    if (e.target.id == 'sold') {
     
      dispatch(newIncome({ key: 'sold', value: Number(e.target.value) }))
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newIncome({ key: 'taxpayer', value: e.target.value }))
    }

    if (e.target.id == 'note') {
      dispatch(newIncome({ key: 'note', value: e.target.value }))
    }

    if (e.target.name == 'caisse') {
      setCaisse(e.target.value)
      dispatch(newIncome({ key: 'caisse', value: e.target.value }))
    }
console.log(newIncome_Data)
  }
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
   
   <SimpleGrid columns={2} spacing={10}>
  


        
        <CoffreUtil Util_type={"Time"} />
        <CoffreUtil Util_type={"Date"} />
        <CoffreUtil Util_type={"Coffre"} />
      


      
     
      
          <TextField id="sold" label="المبلغ " variant="outlined" onChange={(e) => handlenewIncome(e)} />

        
       
          <TextField id="taxpayer" label="المكلف" variant="outlined" onChange={(e) => handlenewIncome(e)} />

   
          <TextField id="note" label="الملاحضة" variant="outlined" onChange={(e) => handlenewIncome(e)} />

      
          <FormControl fullWidth>


           {(localStorage.getItem("user_type") == "admin") ? <InputLabel id="demo-simple-select-label">الى الصندوق</InputLabel>:""}
            {(localStorage.getItem("user_type") == "user") ? "" : <Select
              labelId="demo-simple-select-label"
              name="caisse"
              value={caisse}
              label="caisse"
              size='small'
              onChange={(e) => { handlenewIncome(e) }}
            >
              <MenuItem value={1}>IFRI</MenuItem>
              <MenuItem value={2}>MDD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem>
            </Select>}
            <br></br>

          </FormControl>
      
          </SimpleGrid>
   
    </Box>
  )
}

export default InfoForm