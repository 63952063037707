import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import React from 'react'
import { useState } from "react"
import { Button } from '@mui/material';

//MUI ICONS
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';


// Utils
import supabase from 'src/supabase.config';

// HOcs
import useFilter from 'src/hooks/useFilter';

const SearchFIlter = () => {
    const [age, setAge] = useState('');
const [date, setdate] = useState('2022-09-25')
const [time, settime] = useState('')
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const FilterData = useFilter(date,date);
    const get_Data = async () => {
        await FilterData.then(res => console.log(res))

    }

    return (
        <Box sx={{ width: '100%', height: '100px', backgroundColor: "", mb: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button variant="contained" onClick={get_Data}>
                بحت
                <SearchIcon/>
            </Button>
            <Button variant="contained" sx={{color:'blue',backgroundColor:'white'}} onClick={get_Data}>
                تحديث
                <RestartAltIcon/>
            </Button>
            <FormControl sx={{ width: '130px' }} >
                <InputLabel id="demo-simple-select-label">التاريخ</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '130px' }} >
                <InputLabel id="demo-simple-select-label">الصندوق</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '130px' }} >
                <InputLabel id="demo-simple-select-label">المبلغ المحول</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: '130px' }} >
                <InputLabel id="demo-simple-select-label">المكلف</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export default SearchFIlter