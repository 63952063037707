import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuButton } from '@chakra-ui/react';
import { LocalActivitySharp, MenuOpenSharp, MenuOutlined, Print, PrintOutlined, TransferWithinAStationTwoTone } from '@mui/icons-material';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ApproveTransfer from './ApproveTransfer';
import ShowDetails from './ShowDetails';
// import DetailProcedding from './DetailsProcedding';
// import ApproveProcedding from './ApprovingProcedding';
// import TransferProcedding from './TransferProcedding';
// import PrintCountingProcedding from './PrintCountingProcedding';
// import PrintAproveProcedding from './PrintAproveProcedding';

export default function OperationMenu({data}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
     variant="contained"
        onClick={handleClick}
        sx={{height:'30px',width:'30px'}}
      >
        
   <MenuOutlined  sx={{height:'30px',width:'30px'}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem><DetailProcedding data={data} /> </MenuItem>
        <MenuItem> <ApproveProcedding data={data}/> </MenuItem>
        <MenuItem> <PrintCountingProcedding/> </MenuItem>
    
        <MenuItem>  <PrintAproveProcedding/></MenuItem> */}
        <MenuItem>
          {(data?.validate == false) ? <ApproveTransfer data={data} /> : "لقد تم اضافة التحويل"}
        </MenuItem>
        <MenuItem>
        <ShowDetails data={data} />
        </MenuItem>
      </Menu>
    </div>
  );
}
