import { createSlice } from '@reduxjs/toolkit'
import randomInteger from 'random-int';



const initialState = {
  value: 0,
  new_income: {note:"",sold:0,taxpayer:'',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0},
  new_spend: {note:"",sold:0,taxpayer:'',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0},
  Data_Time:'',
  new_transfer: { note:"",sold: 0,new_sold_out:0,new_sold_in:0, taxpayer: '',caisse_out:0,caisse_in:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0, },
  new_changing: { note:"",sold: 0, taxpayer: '',caisse:0,B2000_out:0,B1000_out:0,B500_out:0,B200_out:0,C200_out:0,C100_out:0,C50_out:0,C20_out:0,C10_out:0,C5_out:0,B2000_in:0,B1000_in:0,B500_in:0,B200_in:0,C200_in:0,C100_in:0,C50_in:0,C20_in:0,C10_in:0,C5_in:0, },
  new_rest: { note:"",sold: 0, taxpayer: '',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0, },
  new_payment: { note:"",sold: 0, taxpayer: '',operation:0,caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0, },
  SearcheActive:false,
  FilterData:[],
  reportDate:"",
  proceedingAlert:false,
  activeInigmaMenu:3,
  updateIncomevar:false
}

export const caisseSlice = createSlice({
  name: 'caisse',
  initialState,
  reducers: {
updateIncome:(state,action)=>{
state.updateIncomevar=action.payload
},
    activeMenu:(state,action)=>{
state.activeInigmaMenu=action.payload
    },

activeProceedingAlert:(state,action)=>{
  console.log("bla bla 2")
state.proceedingAlert=true
},
    updateReportDate:(state,action)=>{
      console.log(action.payload)
state.reportDate=action.payload
    },
    activeSearch:(state,action)=>{
    state.SearcheActive=action.payload
    },
    updateFilterData:(state,action)=>{
      const newData=action.payload
      state.FilterData=newData  
    },
    newIncome: (state, action) => {
      state.new_income[action.payload.key] = action.payload.value
    },
    newTransfer: (state, action) => {
      state.new_transfer[action.payload.key] = action.payload.value
    },
    newPayment: (state, action) => {
      state.new_payment[action.payload.key] = action.payload.value
    },
    newChanging: (state, action) => {
      state.new_changing[action.payload.key] = action.payload.value
    },
    newRest: (state, action) => {
      state.new_rest[action.payload.key] = action.payload.value
    },
    newSpend: (state, action) => {
      state.new_spend[action.payload.key] = action.payload.value
    },
    clearData:(state,action)=>{
      switch (action.payload) {
        case "payment":
          // code block
          const cleared_payment = { sold: 0, taxpayer: '', operation: 0, caisse: 0, B2000: 0, B1000: 0, B500: 0, B200: 0, C200: 0, C100: 0, C50: 0, C20: 0, C10: 0, C5: 0, }
          state.new_payment = cleared_payment
          break;
        case "transfer":
          // code block
          const cleared_transfer =  { sold: 0,new_sold_out:0,new_sold_in:0, taxpayer: '',caisse_out:0,caisse_in:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0, }
          state.new_transfer = cleared_transfer
          break;
        case "rest":
          // code block
          const cleared_rest =  { sold: 0, taxpayer: '',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0, }
          state.new_rest = cleared_rest
          break;
        case "change":
          // code block
        const cleared_change = { sold: 0, taxpayer: '',caisse:0,B2000_out:0,B1000_out:0,B500_out:0,B200_out:0,C200_out:0,C100_out:0,C50_out:0,C20_out:0,C10_out:0,C5_out:0,B2000_in:0,B1000_in:0,B500_in:0,B200_in:0,C200_in:0,C100_in:0,C50_in:0,C20_in:0,C10_in:0,C5_in:0, }
          state.new_changing = cleared_change
          break;
          case "income":
            // code block
         const cleared_income = {note:"",sold:0,taxpayer:'',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0}
            state.new_income = cleared_income
            break;
            case "spend":
              // code block
              const cleared_spend = {note:"",sold:0,taxpayer:'',caisse:0,B2000:0,B1000:0,B500:0,B200:0,C200:0,C100:0,C50:0,C20:0,C10:0,C5:0}
              state.new_spend = cleared_spend
              break;     
      }

   
    },
    updateDataTime: (state, action) => {
      console.log(action.payload)
      state.Data_Time = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {updateIncome,activeMenu,activeProceedingAlert,updateReportDate,clearData,updateFilterData,activeSearch, newIncome, newSpend, newTransfer,updateDataTime,newRest,newPayment,newChanging} = caisseSlice.actions




export default caisseSlice.reducer