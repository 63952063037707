import { Chip } from '@mui/material'
import React from 'react'

const CaisseComponent = (props) => {
    return (

        <Chip sx={{color:"blue"}} label={props?.value == 1 ? "IFRI" : props?.value == 2 ? "MMD" : props?.value == 3 ? "MMG":props?.value == 10 ? "Test" : ""} />
    )
}

export default CaisseComponent