import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Details, InfoOutlined } from '@mui/icons-material';
import DetailsForm from '../income_caisse/detailsForm';
import DetailsShow from './DetailShow';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height:550,
  bgcolor: 'white',
    borderRadius:1,
  boxShadow: 24,
  p: 4,
};

export default function ShowDetails({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
      <InfoOutlined/>     تفاصيل المحضر
         </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
          تفاصيل المحضر
          </Typography>

        <DetailsShow  data={data}  />
        <br></br>
        <br></br>
        <Button variant="contained" onClick={handleClose}>
خروج
        </Button>
        </Box>
     
      </Modal>
    </div>
  );
}
