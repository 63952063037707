import React from 'react'

// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'

const detailsForm = () => {
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      <Chip sx={{ mb: 4, backgroundColor: "yellowgreen", color: "white" }} label="  المبلغ الورقي 1231.213,00 دج"   />
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="outlined-basic" label="  2000 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="1000 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="500 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="200 دج" variant="outlined" />

        </Grid>

      </Grid>
      <br></br>
      <Chip sx={{ mb: 4, backgroundColor: "yellowgreen", color: "white" }} label="  المبلغ المعدني 1231.213,00 دج" />
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="outlined-basic" label="200 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="100 دج" variant="outlined" />

        </Grid>

        <Grid item xs={3}>
          <TextField id="outlined-basic" label="50 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="20 دج" variant="outlined" />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="outlined-basic" label="10 دج" variant="outlined" />

        </Grid>
        <Grid item xs={3}>
          <TextField id="outlined-basic" label="5 دج" variant="outlined" />

        </Grid>


      </Grid>
    </Box>
  )
}

export default detailsForm