import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


//Sub Components
import InfoForm from './infoForm';
import DetailsForm from "./detailsForm"
import ThankyouForm from "./thankyouForm"


// Supabase
import supabase from 'src/supabase.config';

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { newTransfer } from "../../redux/slice"
import { get_company_sold, insert_income_history, insert_spend_history } from 'src/utils/utilsSupabase';

const steps = ['ادخال المعلومات', 'ادخال تفاصيل المبلغ  '];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dispatch = useDispatch()



  const Transfer_Data = useSelector((state) => state.caisse.new_transfer)
  const handleTransfer = async () => {
    handleNext()
    console.log(Transfer_Data)

    get_company_sold(Transfer_Data.caisse_out).then(res=>{
     
      
      insert_spend_history(res,Transfer_Data.caisse_out,Number(Transfer_Data.sold),"OUT","transfer",Transfer_Data)

    })

    get_company_sold(Transfer_Data.caisse_in).then(res=>{
      
      
      insert_income_history(res,Transfer_Data.caisse_in,Number(Transfer_Data.sold),"IN","transfer",Transfer_Data)

    })

    // const { data, errorhistory } = await supabase
    // .from('coffre_history')
    // .insert([
    //   { PrevSold: 0, NewSold: 0 + Rest_data.sold, Type: 'IN', sold: Rest_data.sold },
    //   { PrevSold: 0, NewSold: 0 + Rest_data.sold, Type: 'OUT', sold: Rest_data.sold },
   
    // ])

// Get Balance of caisse In
//     let { data: balance, errorcaisse } = await supabase
//   .from('caisse')
//   .select('balance')
//   .eq('id', Transfer_Data.caisse_in)
// if(balance){
//   // Add New Balance
//   const { data:add_balance, error_add } = await supabase
//   .from('caisse')
//   .update({ balance:balance[0].balance+Number(Transfer_Data.sold) })
//   .eq('id', Transfer_Data.caisse_in)
//   if(error_add)console.log(error_add)
// }
   
// //Get Balance of Caisse Out

//     let { data: balance_out, errorcaisse_out } = await supabase
//     .from('caisse')
//     .select('balance')
//     .eq('id', Transfer_Data.caisse_out)
//   if(balance_out){

//     // Remove Balance
//     const { data:remove_balance, error_out } = await supabase
//     .from('caisse')
//     .update({ balance:balance_out[0].balance-Number(Transfer_Data.sold) })
//     .eq('id', Transfer_Data.caisse_out)
//     if(error_out)console.log(error_out)
//   }
     


    const { data:income, errorincome } = await supabase
      .from('incomes_coffre')
      .insert([
        { sold: Transfer_Data.sold, type: 'transfer', caisse: Transfer_Data.caisse_in, B2000: Transfer_Data.B2000, B1000: Transfer_Data.B1000, B500: Transfer_Data.B500, B200: Transfer_Data.B200, C200: Transfer_Data.C200, C100: Transfer_Data.C100, C50: Transfer_Data.C50, C20: Transfer_Data.C20, C10: Transfer_Data.C10, C5: Transfer_Data.C5},
      ])
      .select()
     
      if(errorincome)console.log(errorincome)
      
    if (income) {

       
      const { data: spend, error } = await supabase
        .from('spends_coffre')
        .insert([
          { sold: Transfer_Data.sold, type: 'transfer', caisse: Transfer_Data.caisse_out, B2000: Transfer_Data.B2000, B1000: Transfer_Data.B1000, B500: Transfer_Data.B500, B200: Transfer_Data.B200, C200: Transfer_Data.C200, C100: Transfer_Data.C100, C50: Transfer_Data.C50, C20: Transfer_Data.C20, C10: Transfer_Data.C10, C5: Transfer_Data.C5},
        ])
        .select()
        if(error)console.log(error)
        
      if (spend) {
      
        const { datatransfer, errortransfer } = await supabase
          .from('Ttransfer_coffre')
          .insert([
            { sold: Transfer_Data.sold, errand_period: Transfer_Data.errand_period, errand_type: Transfer_Data.errand_type, income: income[0].id, spend: spend[0].id, Caisse_in: Transfer_Data.caisse_in, Caisse_out: Transfer_Data.caisse_out, B2000: Transfer_Data.B2000, B1000: Transfer_Data.B1000, B500: Transfer_Data.B500, B200: Transfer_Data.B200, C200: Transfer_Data.C200, C100: Transfer_Data.C100, C50: Transfer_Data.C50, C20: Transfer_Data.C20, C10: Transfer_Data.C10, C5: Transfer_Data.C5},
          ])
          .select()
          if(errortransfer)console.log(errortransfer)
        if (datatransfer) {
        
          console.log(datatransfer)
        }
      }
    }

    
 
  }
  return (
    <Box sx={{ width: '100%', direction: "rtl" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <ThankyouForm />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>اعادة</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {(activeStep + 1 == 1) ?
            <InfoForm /> :
            (activeStep + 1 == 2)
              ? <DetailsForm />
              : <DetailsForm />
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mt: 10 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              رجوع
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}



            {
              (activeStep === steps.length - 1) ?
                <Button onClick={handleTransfer}>
                  حفظ
                </Button> :
                <Button onClick={handleNext}>
                  التالي
                </Button>

            }
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
