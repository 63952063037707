import React, { useState,useEffect } from 'react'


// Material Ui
import { Grid, Box, TextField, Chip, OutlinedInput, InputAdornment, Typography, Button } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { newPayment } from 'src/redux/slice';
import { useDispatch, useSelector } from 'react-redux';


import supabase from 'src/supabase.config';
import AddNewOperation from './AddNewOperation';

const InfoForm = () => {
  const dispatch = useDispatch();
  const Payment_Data = useSelector((state) => state.caisse.new_payment);
  const [company, setCompany] = useState(0);
  const [operation, setoperation] = useState(0);
  const [sold, setsold] = useState(0)
const [OperationType, setOperationType] = useState([])
 



  const handlenewPayment = async (e) => {
    if (e.target.id == 'sold') {
      dispatch(newPayment({ key: 'sold', value: e.target.value }))
      setsold(e.target.value)
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newPayment({ key: 'taxpayer', value: e.target.value }))
    }
    if (e.target.name == 'caisse') {

      setCompany(e.target.value)
      dispatch(newPayment({ key: 'caisse', value: e.target.value }))
    }
    if (e.target.id == 'note') {

      setCompany(e.target.value)
      dispatch(newPayment({ key: 'note', value: e.target.value }))
    }
    if (e.target.name == 'operation') {

      setoperation(e.target.value)
      dispatch(newPayment({ key: 'operation', value: e.target.value }))
    }
    console.log(Payment_Data)

  }


  const get_Operations=async()=>{
    let { data: OperationType, error } = await supabase
    .from('OperationType')
    .select('id,Text')
    if(OperationType)setOperationType(OperationType)
  
  }

  useEffect(() => {
    get_Operations()
  
   
  }, [])



     // Subscribe to changes in any record from the collection
     supabase
     .channel('public:OperationType')
     .on('postgres_changes', { event: '*', schema: 'public', table: 'OperationType' }, payload => {

      get_Operations()


     })
     .subscribe()
  
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography  >
            مبلغ الدفع
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth  >
            <InputLabel htmlFor="outlined-adornment-amount" sx={{ direction: "ltr" }}>مبلغ الدفع      </InputLabel>
            <OutlinedInput
              sx={{ direction: "rtl" }}
              id="sold"
              value={sold}
              size='small'
              onChange={(e) => handlenewPayment(e)}
              endAdornment={<InputAdornment position="end" > دج</InputAdornment>}

            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography  >
            المكلف
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField id="taxpayer" label="المكلف" size='small' variant="outlined" onChange={(e) => handlenewPayment(e)} />

          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography  >
            الصندوق
          </Typography>
        </Grid>
        <Grid item xs={6}>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">الصندوق</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="caisse"
              value={company}
              label="company"
              size='small'
              onChange={(e) => handlenewPayment(e)}
            >
              <MenuItem value={1}>IFRI</MenuItem>
              <MenuItem value={2}>MDD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem>
            </Select>
          </FormControl>

        </Grid>
        <Grid item xs={6}>
          <Typography  >
            نوع العملية
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">نوع العملية</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="operation"
              value={operation}
              label="operation"
              size='small'
              onChange={(e) => handlenewPayment(e)}
            >
              
              
              {
                OperationType.map(TypeOperation=>{
                  return(
                    <MenuItem key={TypeOperation.id} value={TypeOperation.id}>{TypeOperation.Text}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </Grid>
       

        <Grid item xs={6}>
          
         <AddNewOperation/>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
        <TextField id="note" label=" ملاحضة" variant="outlined" size='small'    onChange={(e) => handlenewPayment(e)} />

        </FormControl>

        </Grid>
      </Grid>
    </Box>
  )
}

export default InfoForm
