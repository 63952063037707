import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import supabase from "../../supabase.config"
// mock



// Resuable Components
import Menuaction from "./menuaction"
import MenuPopover from 'src/components/MenuPopover';
import IncomeTable from "./incomeTable"
import NewModal from "./newModal"
import SearchFilter from "./searchFIlter"


//Libs
import currencyFormatter from "currency-formatter"
import { useSelector } from 'react-redux';
import PaymentTable from './PaymentTable';


export default function Change_coffre() {
  const SearcheActive=useSelector((state)=>state.caisse.SearcheActive)
 const [operationcount, setoperationcount] = useState(0)
 const [TotalSOld, setTotalSOld] = useState(0)
 var Total=0
   // Similar to componentDidMount and componentDidUpdate:
   useEffect(() => {
    const get_payments=async()=>{
      let { data: payments_coffre, error } = await supabase
      .from('payments_coffre')
      .select('sold', { count: 'exact'})
      if(payments_coffre){console.log(payments_coffre)
      
        setoperationcount(payments_coffre.length)
        for(let i=0;i< payments_coffre.length;i++){
          Total=Total+payments_coffre[i].sold
        }
        setTotalSOld(Total)
      }
    }
    get_payments()

    supabase
    .channel('*')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'payments_coffre' }, payload => {
      console.log('Change received!', payload)
      //Leaten if data inserted update the table
      get_payments()
    })
    .subscribe()
  },[]);
  return (
    <Page title="Changement Coffre">
      <Container sx={{direction:"rtl"}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{color:'yellowgreen'}}>
          دفع الفواتير
          </Typography>
       

          <NewModal/>
        </Stack>
{/* <SearchFilter/> */}
{/* <Box sx={{width:'100%',height:'50px' ,display:'flex',justifyContent:"space-between"}}>
<Chip sx={{backgroundColor:"#6495ED",color:"white",fontWeight:"bold"}} label={"    عدد العمليات     "+operationcount}/>

          <Chip sx={{ backgroundColor: "yellowgreen", color: "white", direction: "rtl",fontWeight:"bold" }} label={ "اجمالي مبلغ الفواتير     "+

            currencyFormatter.format(TotalSOld, {
              symbol: "دج",
              decimal: ",",
              thousand: ".",
              precision: 2,
              format: "%v %s", // %s is the symbol and %v is the value
            }) } />


</Box> */}
        <Card>
         
          {/* <Scrollbar>
           <IncomeTable/>
          </Scrollbar> */}
          <PaymentTable/>

        
        </Card>
      </Container>
    </Page>
  );
}
