import React from 'react'


// Material Ui
import { Grid, Box, TextField, Chip, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { newIncome } from 'src/redux/slice'
import { useDispatch, useSelector } from 'react-redux'


const InfoForm = () => {
  const dispatch = useDispatch()
  const [caisse, setCaisse] = React.useState(0)
  const newIncome_Data=useSelector(state=>state.caisse.new_income)
  const handlenewIncome = async (e) => {
    if (e.target.id == 'sold') {
     
      dispatch(newIncome({ key: 'sold', value: Number(e.target.value) }))
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newIncome({ key: 'taxpayer', value: e.target.value }))
    }

    if (e.target.id == 'note') {
      dispatch(newIncome({ key: 'note', value: e.target.value }))
    }
    if (e.target.id == 'bank') {
      dispatch(newIncome({ key: 'bank', value: e.target.value }))
    }

    if (e.target.name == 'caisse') {
      setCaisse(e.target.value)
      dispatch(newIncome({ key: 'caisse', value: e.target.value }))
    }
console.log(newIncome_Data)
  }
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      
      <Grid container spacing={1}>

        <Grid item xs={6}>
          <TextField id="sold" label="المبلغ "     variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={6}>
          <TextField id="taxpayer" label="المكلف" variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={6}>
          <TextField id="note" label="الملاحضة" variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={6}>
          <TextField id="bank" label="البنك" variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>


            <InputLabel id="demo-simple-select-label">الى الصندوق</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="caisse"
              value={caisse}
              label="caisse"
               variant="outlined"
              onChange={(e) => { handlenewIncome(e) }}
              sx={{border:"2px solid blue"}}
            >
              <MenuItem value={1}>IFRI</MenuItem>
              <MenuItem value={2}>MDD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem>
            </Select>
            <br></br>

          </FormControl>
        </Grid>

      </Grid>
    </Box>
  )
}

export default InfoForm