import { Chip } from '@mui/material';
import React, { useState, useEffect } from 'react'
import supabase from 'src/supabase.config';

// //Supabase SWR
// import { useClient, useSelect, useQuery } from 'supabase-swr';


const Caisse = ({ id }) => {
    const [caisseName, setcaisseName] = useState("")
    useEffect(() => {

// const get_caisse_swr=useQuery<Todo>('caisse', {
//     filter: (query) => query.order('id', { ascending: false }),
//   }, []);
//   const {
//     data: {
//       data: caisse,
//     },
//     mutate,
//   } = useSelect(todosQuery, {
//     // any swr config
//     revalidateOnMount: true,
//     suspense: true,
//   });

        const get_caisse = async () => {

            let { data: caisse, error } = await supabase
                .from('caisse')
                .select('name')
                .eq('id', id)
            if (caisse) setcaisseName(caisse[0].name)
            if (error) console.log(error)
        }
        get_caisse()
        // get_caisse_swr()
    }, []);
    return (
        <>
            <Chip sx={{color:"blue"}} label={caisseName} />
        </>



    )
}

export default Caisse