import React from 'react'


// Material Ui
import { Grid, Box, TextField, Chip, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


//Redux 
import { useDispatch, useSelector } from 'react-redux'
import { newChanging } from 'src/redux/slice';

//SUpabase
import supabase from 'src/supabase.config';

const InfoForm = () => {
  const dispatch = useDispatch()
  const Changing_Data = useSelector((state) => state.caisse.new_changing)


  const [company, setCompany] = React.useState(0)

  const handlenewChanging = async (e) => {
    if (e.target.id == 'sold') {
      dispatch(newChanging({ key: 'sold', value: e.target.value }))
    }
    if (e.target.id == 'taxpayer') {
      dispatch(newChanging({ key: 'taxpayer', value: e.target.value }))
    }
    if (e.target.name == 'caisse') {

      setCompany(e.target.value)
      dispatch(newChanging({ key: 'caisse', value: e.target.value }))
    }
    console.log(Changing_Data)

  }


  return (
    <Box sx={{ width: '100%', height: '350px', backgroundColor: "white", mt: 2 }}>
      <Grid container spacing={1}>
      <Grid item xs={6}>
     <Typography  >
        المبلغ المحول
      </Typography> 
     </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField id="sold"  size='small' label="المبلغ المحول" variant="outlined" onChange={(e) => handlenewChanging(e)} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
     <Typography  >
          المكلف
      </Typography> 
     </Grid>
        <Grid item xs={6}>

          <FormControl fullWidth>
            <TextField id="outlined-basic" size='small' label="المكلف" variant="outlined" onChange={(e) => handlenewChanging(e)} />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
     <Typography  >
          الصندوق
      </Typography> 
     </Grid>
        <Grid item xs={6}>


          <FormControl fullWidth>
            <InputLabel id="caisse_out">  الصندوق</InputLabel>
            <Select
              labelId="caisse"
              name="caisse"
              value={company}
              label="caisse"
              size='small'
              onChange={(e) => { handlenewChanging(e); console.log(e.target.name) }}>
              <MenuItem value={1}>IFRI</MenuItem>
              <MenuItem value={2}>MDD</MenuItem>
              <MenuItem value={3}>MMG</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default InfoForm



// <Chip sx={{ mb: 5 }} label={(company==1)?"IFRI":(company==2)?"MDD":"MMG"} />
// <Grid container spacing={1}>

//   <Grid item xs={6}>

//     <Chip label='  التاريخ 12-01-2022      ' />
//   </Grid>
//   <Grid item xs={6}>
//     <Chip label=' الساعة  12:01:01      ' />

//   </Grid>


// </Grid>
// <br></br>
// <Grid container spacing={1}>

//   <Grid item xs={6}>
//     <TextField id="sold" label="المبلغ المحول" variant="outlined" onChange={(e)=>handlenewChanging(e)}/>

//   </Grid>
//   <Grid item xs={6}>
//     <TextField id="outlined-basic" label="المكلف" variant="outlined"  onChange={(e)=>handlenewChanging(e)}/>

//   </Grid>
//   <Box  sx={{width:'60%',mt:5}}>


//   <FormControl fullWidth>
//     <InputLabel id="caisse_out">  الصندوق</InputLabel>
//     <Select
//       labelId="caisse"
//       name="caisse"
//       value={company}
//       label="caisse"
//       onChange={(e) => { handlenewChanging(e); console.log(e.target.name) }}
//     >
//       <MenuItem value={1}>IFRI</MenuItem>
//       <MenuItem value={2}>MDD</MenuItem>
//       <MenuItem value={3}>MMG</MenuItem>
//     </Select>
//   </FormControl>
//   </Box>
// </Grid>