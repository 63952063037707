import { useEffect } from 'react'

// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Paper, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import supabase from 'src/supabase.config';

//Libs
import currencyFormatter from "currency-formatter"
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------


const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  width: theme.spacing(2),
  height: theme.spacing(1),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({caisse, income, spend, title, total, icon, color = 'primary', sx, ...other }) {

  return (
    // <Card
    //   sx={{
    //     py: 5,
    //     boxShadow: 0,
    //     textAlign: 'center',
    //     color: (theme) => theme.palette[color].darker,
    //     bgcolor: (theme) => theme.palette[color].lighter,
    //     ...sx,
    //   }}
    //   {...other}
    // >
    //   <IconWrapperStyle
    //     sx={{
    //       color: (theme) => theme.palette[color].dark,
    //       // backgroundImage: `url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDR0149AQRSbfnGIO5kW2rKTPArHzRZ_BSww&usqp=CAU")` ,
    //       // backgroundSize:"cover"
    //       backgroundColor:'GhostWhite'
    //       // (theme) =>
    //       //   `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
    //       //     theme.palette[color].dark,
    //       //     0.24
    //       //   )} 100%)`,
    //     }}
    //   >
    //     <Typography     sx={{color:'yellowgreen',direction:"rtl"}}> 
    //     الرصيد
    //      { currencyFormatter.format(total, {
    //             symbol: "دج",
    //             decimal: ",",
    //             thousand: ".",
    //             precision: 2,
    //             format: "%v %s", // %s is the symbol and %v is the value
    //           })}  </Typography>
    //   </IconWrapperStyle>

    //   <Typography     sx={{color:'yellowgreen',direction:"rtl"}}>  { currencyFormatter.format(income, {
    //             symbol: "دج",
    //             decimal: ",",
    //             thousand: ".",
    //             precision: 2,
    //             format: "%v %s", // %s is the symbol and %v is the value
    //           })} <Iconify icon={'ant-design:arrow-up'} width={15} height={15} /> </Typography>
    //   <Typography    sx={{color:'tomato',direction:"rtl"}}> {
    //   currencyFormatter.format(spend, {
    //             symbol: "دج",
    //             decimal: ",",
    //             thousand: ".",
    //             precision: 2,
    //             format: "%v %s", // %s is the symbol and %v is the value
    //           })}


    //     <Iconify icon={'ant-design:arrow-down'} width={15} height={15} /> </Typography>

    //   <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
    //     {title}
    //   </Typography>
    // </Card>
    //     <Paper  elevation={3} sx={{height:"150px",width:'280px'}}>
    // <Typography>13.989,00 $</Typography>

    //     </Paper>^

    <Card
      sx={{
        height: "180px", width: '300px',
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      {/* <IconWrapperStyle
  sx={{
    color: (theme) => theme.palette[color].dark,
    backgroundImage: (theme) =>
      `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
        theme.palette[color].dark,
        0.24
      )} 100%)`,
  }}
>
  <Iconify icon={icon} width={24} height={24} />
</IconWrapperStyle> */}

      <Typography variant="h5" >  {
        currencyFormatter.format(total, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })}    </Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
      <Typography sx={{ color: 'yellowgreen', direction: "rtl",fontSize:"13px" }}>  {currencyFormatter.format(income, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
      })} <Iconify icon={'ant-design:arrow-up'} width={15} height={15} /> </Typography>
      <Typography sx={{ color: 'tomato', direction: "rtl",fontSize:"13px" }}> {
        currencyFormatter.format(spend, {
          symbol: "دج",
          decimal: ",",
          thousand: ".",
          precision: 2,
          format: "%v %s", // %s is the symbol and %v is the value
        })}


        <Iconify icon={'ant-design:arrow-down'} width={15} height={15} /> </Typography>
    </Card>
  );
}
