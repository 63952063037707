import { Typography,Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { check_conexion } from 'src/utils/syncio'
import ArchiveTable from './ArchiveTable'
import Operation_Table from "./Operations_Table"
import useConnectionStatus from "check-connection";
import {useEffect,useState} from "react"
 import axios from "axios"
 
 import isReachable from "is-reachable"
const Operation_in_out = () => {
   
    
 

   
useEffect(() => {
  
   
    
}, [])

    return (



        <Box    sx={{direction:"rtl"}}>

             <Box sx={{height:"100px",width:'100%',backgroundColor:"",direction:"rtl",padding:'20px'}}>
<Typography sx={{fontWeight:"bold"}}>
أرشيف  الصناديق

</Typography>

            </Box>

        <ArchiveTable/>
        </Box>
    )
}

export default Operation_in_out