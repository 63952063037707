import * as React from 'react';
import {useState} from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import supabase from 'src/supabase.config';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 300,
    bgcolor: 'white',
    borderRadius: 1,

    boxShadow: 24,
    p: 4,
};

export default function AddNewOperation() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [Operation_Text, setOperation_Text] = useState("")
const add_new_operation=async()=>{

    const { data, error } = await supabase
    .from('OperationType')
    .insert([
      { Text:Operation_Text }
    ])
    .select()
    if(data)handleClose()
  
}
    return (
        <div>

            <Button variant="contained" onClick={handleOpen}>
                +   اضافة عملية جديدة
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} dir="rtl">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        اضافة عملية

                    </Typography>
                    <TextField id="outlined-basic" label="اسم العملية" variant="outlined"   onChange={(e)=>setOperation_Text(e.target.value)} />
                    <Box sx={{ width: '100%', display: "flex", height: '40px', marginTop: 5 }}>
                        <Button variant="contained" sx={{ marginLeft: 10 }} onClick={add_new_operation}>
                            اضافة
                        </Button>
                        <Button variant="contained">
                            الغاء
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
