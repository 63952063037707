import supabase from "src/supabase.config";
import currencyFormatter from "currency-formatter"




export const get_all_changes = async (startDate, endDate) => {
    let { data: changement, error } = await supabase
        .from('changement')
        .select('*')
        // .gte('created_at', startDate)
        // .lte('created_at', endDate)
    if (changement)
        return changement
}


export const format_sold = (sold) => {
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
    })
}


export const format_caisse = (caisse) => {
    switch (caisse) {
        case 1:
            return 'IFRI'
            break;
        case 2:
            return 'MMD'
            break;

        case 3:
            return 'MMG'
            break;


        default:
            break;
    }
}


export const format_Time=(Time)=>{
    return Time.split("T")[1].split('.')[0]

}




export const Edit_income=async(income_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:incomes_coffre, error } = await supabase
  .from('incomes_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', income_id)
  .select()
  if(incomes_coffre)return incomes_coffre
  if(error)console.log(error)
  
  }



  export const Edit_spend=async(spend_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:spends_coffre, error } = await supabase
  .from('spends_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', spend_id)
  .select()
  if(spends_coffre)return spends_coffre
  if(error)console.log(error)
  
  }



  
export const Edit_change = async (changement_id, B2000, B1000, B500, B200, C200, C100, C50, C20, C10, C5,B2000_in, B1000_in, B500_in, B200_in, C200_in, C100_in, C50_in, C20_in, C10_in, C5_in) => {
    let PaperSold = B2000 * 2000 + B1000 * 1000 + B500 * 500 + B200 * 200
    let CoinSold = C200 * 200 + C100 * 100 + C50 * 50 + C20 * 20 + C10 * 10 + C5 * 5
    let Sold = B2000 * 2000 + B1000 * 1000 + B500 * 500 + B200 * 200 + C200 * 200 + C100 * 100 + C50 * 50 + C20 * 20 + C10 * 10 + C5 * 5
    const { data: changement, error } = await supabase
        .from('changement')
        .update({
            B2000_out: B2000, B1000_out: B1000, B500_out: B500, B200_out: B200, C200_out: C200, C100_out: C100, C50_out: C50, C20_out: C20, C10_out: C10, C5_out: C5,
            B2000_in: B2000_in, B1000_in: B1000_in, B500_in: B500_in, B200_in: B200_in, C200_in: C200_in, C100_in: C100_in, C50_in: C50_in, C20_in: C20_in, C10_in: C10_in, C5_in: C5_in,
            sold: Sold
        })
        .eq('id', changement_id)
        .select()
    if (changement) return changement
    if (error) console.log(error)

}