import supabase from "src/supabase.config";
import currencyFormatter from "currency-formatter"




export const get_all_payments = async (startDate, endDate) => {
    let { data: payments_coffre, error } = await supabase
        .from('payments_coffre')
        .select('*')
        // .gte('created_at', startDate)
        // .lte('created_at', endDate)
    if (payments_coffre)
        return payments_coffre

    if(error)console.log(error)    
}


export const format_sold = (sold) => {
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
    })
}



export const delete_cascad_rests=async(payment_id)=>{
    const { data, error } = await supabase
    .from('rests')
    .delete()
    .eq('payment', payment_id)
    .select()
    if(error)console.log(error)
    if(data){
        delete_cascad_income(data?.income)
    }
}

export const delete_cascad_income=async(income_id)=>{
    const { data, error } = await supabase
    .from('incomes_coffre')
    .delete()
    .eq('id', income_id)
    .select()
    if(error)console.log(error)
    if(data)return data
}

export const delete_cascad_spend=async(spend_id)=>{
    const { data, error } = await supabase
    .from('spends_coffre')
    .delete()
    .eq('id', spend_id)
    .select()
    if(error)console.log(error)
    if(data)return data
}


export const delete_payment=async(payment_id)=>{
    
const { data, error } = await supabase
.from('payments_coffre')
.delete()
.eq('id', payment_id)
.select()
if(error)console.log(error)

}




export const Edit_spend=async(spend_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:spends_coffre, error } = await supabase
  .from('spends_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', spend_id)
  .select()
  if(spends_coffre)return spends_coffre
  if(error)console.log(error)
  
  }

  export const Edit_payment=async(payment_id,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5)=>{
    let PaperSold=B2000*2000+B1000*1000+B500*500+B200*200
    let CoinSold=C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
    let Sold=B2000*2000+B1000*1000+B500*500+B200*200+C200*200+C100*100+C50*50+C20*20+C10*10+C5*5
  const { data:payments_coffre, error } = await supabase
  .from('payments_coffre')
  .update({ B2000:B2000,B1000:B1000,B500:B500,B200:B200,C200:C200,C100:C100,C50:C50,C20:C20,C10:C10,C5:C5,sold:Sold  })
  .eq('id', payment_id)
  .select()
  if(payments_coffre)return payments_coffre
  if(error)console.log(error)
  
  }