import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react'

//Sub Components
import InfoForm from './infoForm';
import DetailsForm from "./detailsForm"
import DetailsForm_in from "./detailsFormIn"
import ThankyouForm from "./thankyouForm"


// Supabase
import supabase from 'src/supabase.config';
import { useSelect } from '@mui/base';
import { useSelector } from 'react-redux';
import { delay, get_company_sold, get_company_sold2, insert_income_history, insert_spend_history } from 'src/utils/utilsSupabase';



const steps = ['ادخال المعلومات', 'ادخال تفاصيل المبلغ المحول', '  ادخال تفاصيل المبلغ الداخل'];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const new_changing = useSelector((state) => state.caisse.new_changing)
  const new_income = useSelector((state) => state.caisse.new_income)
  const new_spend = useSelector((state) => state.caisse.new_spend)
  const [income_id, setincome_id] = useState()
  const [spend_id, setspend_id] = useState()


  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const handleChanging = async () => {

  }
  const [Income_spends_History, setIncome_spends_History] = useState([])


  const getSold = async () => {
    let { data: incomes_coffre, errorincome } = await supabase
      .from('incomes_coffre')
      .select('sold')
    if (incomes_coffre) {

    }

    let { data: spends_coffre, errorspend } = await supabase
      .from('spends_coffre')
      .select('sold')
  }


  const handleIncome = async () => {
    get_company_sold(new_changing.caisse).then(res => {


      insert_spend_history(res, new_changing.caisse, Number(new_changing.sold), "OUT", "changement", new_changing)

    })



    delay(function () {
      // do stuff
      get_company_sold2(new_changing.caisse).then(prevsold => {


        insert_income_history(prevsold, new_changing.caisse, Number(new_changing.sold), "IN", "changement", new_changing)

      })
    }, 5000); // end delay

    console.log('Incomming')
    const { data: income, error } = await supabase
      .from('incomes_coffre')

      .insert([
        { sold: new_income.sold, type: 'changement', caisse: new_changing.caisse, B2000: new_income.B2000, B1000: new_income.B1000, B500: new_income.B500, B200: new_income.B200, C200: new_income.C200, C100: new_income.C100, C50: new_income.C50, C20: new_income.C20, C10: new_income.C10, C5: new_income.C5, },
      ])
      .select()
    if (income) {
      const { data: spend, error } = await supabase
        .from('spends_coffre')
        .insert([
          { sold: new_spend.sold, type: 'changement', caisse: new_changing.caisse, B2000: new_spend.B2000, B1000: new_spend.B1000, B500: new_spend.B500, B200: new_spend.B200, C200: new_spend.C200, C100: new_spend.C100, C50: new_spend.C50, C20: new_spend.C20, C10: new_spend.C10, C5: new_spend.C5, },
        ])
        .select()
      if (error) console.log(error)
      if (spend) {
        console.log(income)
        console.log(spend)
        console.log('new_changing')
        handleNext()
        const { data, error } = await supabase
          .from('changement')
          .insert([
            { change:new_changing.change, sold: new_changing.sold, income: income[0].id, spend: spend[0].id, caisse: new_changing.caisse, B2000_out: spend[0].B2000, B1000_out: spend[0].B1000, B500_out: spend[0].B500, B200_out: spend[0].B200, C200_out: spend[0].C200, C100_out: spend[0].C100, C50_out: spend[0].C50, C20_out: spend[0].C20, C10_out: spend[0].C10, C5_out: spend[0].C5, B2000_in: income[0].B2000, B1000_in: income[0].B2000, B500_in: income[0].B2000, B200_in: income[0].B2000, C200_in: income[0].C200, C100_in: income[0].C100, C50_in: income[0].C50, C20_in: income[0].C20, C10_in: income[0].C10, C5_in: income[0].C5 },
          ])
          .select()
        if (data) console.log('data inserted Stepere')
        if (error) console.log(error)

      }

    }
    if (error) console.log(error)

  }
  const handeSpend = async (income) => {
    const { data: spend, error } = await supabase
      .from('spends_coffre')
      .insert([
        { sold: new_spend.sold, type: 'changement', caisse: new_changing.caisse, B2000: new_spend.B2000, B1000: new_spend.B2000, B500: new_spend.B500, B200: new_spend.B200, C200: new_spend.C200, C100: new_spend.C100, C50: new_spend.C50, C20: new_spend.C20, C10: new_spend.C10, C5: new_spend.C5, },
      ])
    if (error) console.log(error)
    if (spend) {
      console.log(income)
      console.log(spend)
    }
  }
  return (
    <Box sx={{ width: '100%', direction: "rtl" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <ThankyouForm />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>اعادة</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {(activeStep + 1 == 1) ?
            <InfoForm /> :
            (activeStep + 1 == 2)
              ? <DetailsForm />
              : <DetailsForm_in />
          }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              رجوع
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}



            {
              (activeStep === steps.length - 1) ?
                <Button onClick={() => { handleChanging(); handleIncome() }}>
                  حفظ
                </Button> :
                <Button onClick={handleNext}>
                  التالي
                </Button>

            }
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
