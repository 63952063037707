import React from 'react'
import { useState, useEffect } from 'react'


// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { newTransfer } from "../../redux/slice"



import currencyFormatter from "currency-formatter"



//Detial Modal
import DetailModal from "../reporting/infoModal"

const DetailsForm = () => {
  //Paper 
  // const [B2000, setB2000] = useState(0)
  // const [B1000, setB1000] = useState(0)
  // const [B500, setB500] = useState(0)
  // const [B200, setB200] = useState(0)

  //Coin
  // const [C200, setC200] = useState(0)
  // const [C100, setC100] = useState(0)
  // const [C50, setC50] = useState(0)
  // const [C20, setC20] = useState(0)
  // const [C10, setC10] = useState(0)
  // const [C5, setC5] = useState(0)

  // Total Paper and Coin
 
  const [paperSold, setpaperSold] = useState(0)
  const [coinSOld, setcoinSOld] = useState(0)
  

  const [TotalSold, setTotalSold] = useState(0)

  const dispatch = useDispatch()
  const Transfer_Data = useSelector((state) => state.caisse.new_transfer)
  const handlenewTransfer = (e) => {
    switch (e.target.id) {

      case 'B2000':
        dispatch(newTransfer({ key: 'B2000', value: Number(e.target.value) }))
        break;
      case 'B1000':
        dispatch(newTransfer({ key: 'B1000', value:  Number(e.target.value) }))
        break;
      case 'B500':
        dispatch(newTransfer({ key: 'B500', value: Number(e.target.value) }))
        break;
      case 'B200':
        dispatch(newTransfer({ key: 'B200', value: Number(e.target.value) }))
        break;
      case 'C200':
        dispatch(newTransfer({ key: 'C200', value:  Number(e.target.value) }))
        break;
      case 'C100':
        dispatch(newTransfer({ key: 'C100', value:  Number(e.target.value) }))
        break;
      case 'C50':
        dispatch(newTransfer({ key: 'C50', value:  Number(e.target.value) }))
        break;
      case 'C20':
        dispatch(newTransfer({ key: 'C20', value: Number(e.target.value) }))
        break;
      case 'C10':
        dispatch(newTransfer({ key: 'C10', value:  Number(e.target.value)}))
        break;
      case 'C5':
        dispatch(newTransfer({ key: 'C5', value:  Number(e.target.value) }))
        break;
      case 'taxpayer':
        dispatch(newTransfer({ key: 'taxpayer', value:  Number(e.target.value) }))
        break;
      case 'caisse_out':
        dispatch(newTransfer({ key: 'caisse_out', value:  Number(e.target.value) }))
        break;
      case 'caisse_in':
        dispatch(newTransfer({ key: 'caisse_in', value: Number(e.target.value)}))
        break;
      case 'sold':
        dispatch(newTransfer({ key: 'sold', value: Number(e.target.value) }))
        break;

    }
  } 

 
    useEffect(() => {
      setTotalSold(Transfer_Data.B2000*2000+Transfer_Data.B1000*1000+Transfer_Data.B500*500+Transfer_Data.B200*200+Transfer_Data.C200*200+Transfer_Data.C100*100+Transfer_Data.C50*50+Transfer_Data.C20*20+Transfer_Data.C10*10+Transfer_Data.C5*5)
      setpaperSold(Transfer_Data.B2000*2000+Transfer_Data.B1000*1000+Transfer_Data.B500*500+Transfer_Data.B200*200)
      setcoinSOld(Transfer_Data.C200*200+Transfer_Data.C100*100+Transfer_Data.C50*50+Transfer_Data.C20*20+Transfer_Data.C10*10+Transfer_Data.C5*5)
    });
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      <Box  sx={{display:"flex",flexDirection:'column',width:'300px'}}>

     
      <Chip sx={{ mb: 4,ml:2 , backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ الورقي  "+currencyFormatter.format(paperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
                <Chip sx={{ mb: 4,ml:2, backgroundColor: "yellowgreen", color: "white",direction:'rtl' }} label={" المبلغ المعدني  "+currencyFormatter.format(coinSOld, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               <Chip sx={{ mb: 4,ml:2  ,backgroundColor: "yellowgreen", color: "white" }} label={"   المبلغ الاجمالي  "+currencyFormatter.format(TotalSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
              <DetailModal   id= {Transfer_Data.caisse_out}/>
              

               </Box>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="B2000" label="  2000 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B1000" label="1000 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B500" label="500 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B200" label="200 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>

      </Grid>
      <br></br>
     
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C200" label="200 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C100" label="100 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>

        <Grid item xs={3}>
          <TextField id="C50" label="50 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C20" label="20 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C10" label="10 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C5" label="5 دج" variant="outlined" onChange={(e) => handlenewTransfer(e)} />

        </Grid>


      </Grid>
    </Box>
  )
}

export default DetailsForm