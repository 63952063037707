import React,{useState,useEffect} from 'react'

// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'


//Redux 
import { useDispatch, useSelector } from 'react-redux'
import { newChanging, newIncome, newPayment, newRest } from 'src/redux/slice';




//Detial Modal
import DetailModal from "../reporting/infoModal"



//Libs
import currencyFormatter from "currency-formatter"
const DetailsForm = () => {
  const dispatch = useDispatch()
  const Payment_Data = useSelector((state) => state.caisse.new_payment);
  const Rest_Data =useSelector(state=>state.caisse.new_rest)
  const Income_Data =useSelector(state=>state.caisse.new_income)

  const [paperSold, setpaperSold] = useState(0)
const [coinSOld, setcoinSOld] = useState(0)

const [TotalSold, setTotalSold] = useState(0)


  const handlenewChanging = (e) => {
    switch (e.target.id) {

      case 'B2000':
     
        dispatch(newRest({ key: 'B2000', value: e.target.value }))
        dispatch(newIncome({ key: 'B2000', value: e.target.value }))
        break;
      case 'B1000':
        dispatch(newRest({ key: 'B1000', value: e.target.value }))
        dispatch(newIncome({ key: 'B1000', value: e.target.value }))

        break;
      case 'B500':
        dispatch(newRest({ key: 'B500', value: e.target.value }))
        dispatch(newIncome({ key: 'B500', value: e.target.value }))
        break;
      case 'B200':
        dispatch(newRest({ key: 'B200', value: e.target.value }))
        dispatch(newIncome({ key: 'B200', value: e.target.value }))
        break;
      case 'C200':
        dispatch(newRest({ key: 'C200', value: e.target.value }))
        dispatch(newIncome({ key: 'C200', value: e.target.value }))
        break;
      case 'C100':
        dispatch(newRest({ key: 'C100', value: e.target.value }))
        dispatch(newIncome({ key: 'C100', value: e.target.value }))
        break;
      case 'C50':
        dispatch(newRest({ key: 'C50', value: e.target.value }))
        dispatch(newIncome({ key: 'C50', value: e.target.value }))
        break;
      case 'C20':
        dispatch(newRest({ key: 'C20', value: e.target.value }))
        dispatch(newIncome({ key: 'C20', value: e.target.value }))
        break;
      case 'C10':
        dispatch(newRest({ key: 'C10', value: e.target.value }))
        dispatch(newIncome({ key: 'C10', value: e.target.value }))
        break;
      case 'C5':
        dispatch(newRest({ key: 'C5', value: e.target.value }))
        dispatch(newIncome({ key: 'C5', value: e.target.value }))
        break;
      case 'sold':
        dispatch(newRest({ key: 'sold', value: e.target.value }))
        dispatch(newIncome({ key: 'sold', value: e.target.value }))
        break;

    }
    console.log(Rest_Data)
    console.log(Income_Data)
    
  }
  const [enterkeycount, setenterkeycount] = useState(0)
  const B2000Ref1 = React.useRef();
    const B1000Ref2 = React.useRef();
    const B500Ref3 = React.useRef();
    const B200Ref4 = React.useRef();

    const C200Ref = React.useRef();
    const C100Ref = React.useRef();
    const C50Ref = React.useRef();
    const C20Ref = React.useRef();
  
    const C10Ref = React.useRef();
    const C5Ref = React.useRef();
    const incerment_enter_key = (e) => {

      if (e.key === "Enter") {
        setenterkeycount(enterkeycount + 1)
      }
    }
    useEffect(() => {
      switch (enterkeycount) {
        case 1:
          // code block
          B2000Ref1.current.focus();
           
         
          break;
        case 2:
          // code block
          B1000Ref2.current.focus();
           
          break;
  
        case 3:
          // code block
          B500Ref3.current.focus();
  
       
          break;
        case 4:
          // code block
          B200Ref4.current.focus();
          
          break;
       
  
        case 5:
          // code block
  
  
          C200Ref.current.focus();
          break;
        case 6:
          // code block
          C100Ref.current.focus();
          break;
        case 7:
          // code block
          C50Ref.current.focus();
          break;
        case 8:
          // code block
          C20Ref.current.focus();
          break;
        case 9:
          // code block
          C10Ref.current.focus();
          break;
        case 10:
          // code block
          C5Ref.current.focus();
          break;
        case 11:
          // code block
          setenterkeycount(0)
  
          break;
         
  
        // code block
      }
    }, [enterkeycount]);


  useEffect(() => {
    
    setTotalSold(Rest_Data.B2000*2000+Rest_Data.B1000*1000+Rest_Data.B500*500+Rest_Data.B200*200+Rest_Data.C200*200+Rest_Data.C100*100+Rest_Data.C50*50+Rest_Data.C20*20+Rest_Data.C10*10+Rest_Data.C5*5)
    setpaperSold(Rest_Data.B2000*2000+Rest_Data.B1000*1000+Rest_Data.B500*500+Rest_Data.B200*200)
    setcoinSOld(Rest_Data.C200*200+Rest_Data.C100*100+Rest_Data.C50*50+Rest_Data.C20*20+Rest_Data.C10*10+Rest_Data.C5*5)
  });
  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }} onKeyPress={(e) => incerment_enter_key(e)}>
      
      <Chip sx={{ mb: 4,ml:2 , backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ الورقي  "+currencyFormatter.format(paperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
                <Chip sx={{ mb: 4,ml:2, backgroundColor: "yellowgreen", color: "white",direction:'rtl' }} label={" المبلغ المعدني  "+currencyFormatter.format(coinSOld, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               <Chip sx={{ mb: 4,ml:2  ,backgroundColor: "yellowgreen", color: "white" }} label={"   المبلغ الاجمالي  "+currencyFormatter.format(TotalSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />

              <DetailModal id={Rest_Data.caisse}/>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="B2000" label="  2000 دج" value={Rest_Data.B2000} inputRef={B2000Ref1} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B1000" label="1000 دج" value={Rest_Data.B1000} inputRef={B1000Ref2} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B500" label="500 دج" value={Rest_Data.B500} inputRef={B500Ref3} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B200" label="200 دج" value={Rest_Data.B200}  inputRef={B200Ref4} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>

      </Grid>
      <br></br>
   
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C200" label="200 دج" value={Rest_Data.C200}  inputRef={C200Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C100" label="100 دج"  value={Rest_Data.C100} inputRef={C100Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>

        <Grid item xs={3}>
          <TextField id="C50" label="50 دج" value={Rest_Data.C50}    inputRef={C50Ref}  variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C20" label="20 دج" value={Rest_Data.C20}  inputRef={C20Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C10" label="10 دج" value={Rest_Data.C10}   inputRef={C10Ref} variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C5" label="5 دج" value={Rest_Data.C5} inputRef={C5Ref}  variant="outlined" onChange={(e) => handlenewChanging(e)} />

        </Grid>


      </Grid>
    </Box>
  )
}

export default DetailsForm