import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Grid, TextField } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { PDFViewer, Font } from '@react-pdf/renderer';
import Tajwal from "./fonts/tajawal-arabic-400-normal.ttf"
import { PrintSharp } from '@mui/icons-material';
import ENTETE from "./images/ENTETE.png"
import Row from "./printTemplate/row"
import Header from "./printTemplate/header"
import Footer from './printTemplate/Footer';
import Tete from './printTemplate/Tete';
import axios from "axios"

import supabase from 'src/supabase.config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 600,
  bgcolor: 'white',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};



Font.register({
  family: 'Tajwal',
  src: Tajwal

});
// Create styles
const styles = StyleSheet.create({

  page: {
    flexDirection: 'column',
    display: 'flex',

    alignItems: 'center',
    backgroundColor: 'white',
    paddingRight: '100px',
    width: 700,
    padding: 5,
    height: 500,
  },
  section: {
    margin: 10,
    padding: 10,

    paddingRight: '100px',
  },
  container: {
    width: 600,

    height: 500,
    backgroundColor: "white",
  },
  text: {
    fontFamily: "Tajwal",
    fontSize: "10px"
  },
  image: {
    height: '60px',
    width: "100%"
  }
});


export default function BasicModal({ id }) {

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [detils_Data, setdetils_Data] = useState()
  //Income
  var TotalB2000 = 0, TotalB1000 = 0, TotalB500 = 0, TotalB200 = 0
  var TotalC200 = 0, TotalC100 = 0, TotalC50 = 0, TotalC20 = 0, TotalC10 = 0, TotalC5 = 0
  //Spend
  var TotalSpend_B2000 = 0, TotalSpend_B1000 = 0, TotalSpend_B500 = 0, TotalSpend_B200 = 0
  var TotalSpend_C200 = 0, TotalSpend_C100 = 0, TotalSpend_C50 = 0, TotalSpend_C20 = 0, TotalSpend_C10 = 0, TotalSpend_C5 = 0
  var Details;
  var SpendsSold = 0
  var IncomeSold = 0
  var Balance = 0;
  useEffect(() => {

    const get_details = async () => {



      let { data: incomes_coffre, error } = await supabase
        .from('incomes_coffre')
        .select('sold,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
        .eq('caisse', id)
        .filter('Date', 'eq', today)
      if (incomes_coffre) {
        console.log('hey filter')
        console.log(incomes_coffre)
        for (let i = 0; i < incomes_coffre.length; i++) {
          IncomeSold = IncomeSold + incomes_coffre[i].sold
          TotalB2000 = incomes_coffre[i].B2000 + TotalB2000
          TotalB1000 = TotalB1000 + incomes_coffre[i].B1000
          TotalB500 = TotalB500 + incomes_coffre[i].B500
          TotalB200 = TotalB200 + incomes_coffre[i].B200


          TotalC200 = TotalC200 + incomes_coffre[i].C200
          TotalC100 = TotalC100 + incomes_coffre[i].C100
          TotalC50 = TotalC50 + incomes_coffre[i].C50
          TotalC20 = TotalC20 + incomes_coffre[i].C20
          TotalC10 = TotalC10 + incomes_coffre[i].C10
          TotalC5 = TotalC5 + incomes_coffre[i].C5

        }
      }



      let { data: spends_coffre, errorspend } = await supabase
        .from('spends_coffre')
        .select('sold,B2000,B1000,B500,B200,C200,C100,C50,C20,C10,C5')
        .eq('caisse', id)
        .filter('Date', 'eq', today)
      if (spends_coffre) {
        console.log('hey')
        console.log(spends_coffre)
        for (let i = 0; i < spends_coffre.length; i++) {
          SpendsSold = SpendsSold + spends_coffre[i].sold
          TotalSpend_B2000 = spends_coffre[i].B2000 + TotalSpend_B2000
          TotalSpend_B1000 = TotalSpend_B1000 + spends_coffre[i].B1000
          TotalSpend_B500 = TotalSpend_B500 + spends_coffre[i].B500
          TotalSpend_B200 = TotalSpend_B200 + spends_coffre[i].B200


          TotalSpend_C200 = TotalSpend_C200 + spends_coffre[i].C200
          TotalSpend_C100 = TotalSpend_C100 + spends_coffre[i].C100
          TotalSpend_C50 = TotalSpend_C50 + spends_coffre[i].C50
          TotalSpend_C20 = TotalSpend_C20 + spends_coffre[i].C20
          TotalSpend_C10 = TotalSpend_C10 + spends_coffre[i].C10
          TotalSpend_C5 = TotalSpend_C5 + spends_coffre[i].C5


        }
      }


      setdetils_Data({
        TotalB2000: Number(TotalB2000 - TotalSpend_B2000),
        TotalB1000: Number(TotalB1000 - TotalSpend_B1000),
        TotalB500: Number(TotalB500 - TotalSpend_B500),
        TotalB200: Number(TotalB200 - TotalSpend_B200),

        TotalC200: Number(TotalC200 - TotalSpend_C200),
        TotalC100: Number(TotalC100 - TotalSpend_C100),
        TotalC50: Number(TotalC50 - TotalSpend_C50),
        TotalC20: Number(TotalC20 - TotalSpend_C20),
        TotalC10: Number(TotalC10 - TotalSpend_C10),
        TotalC5: Number(TotalC5 - TotalSpend_C5),
        Balance: Number(IncomeSold - SpendsSold),
      })
    }
    get_details()



  }, []);
  console.log(detils_Data?.TotalB200)
  return (
    <div>
      <Button variant="outlined" onClick={handleOpen} startIcon={<PrintSharp />} sx={{ direction: 'ltr' }}>
        طباعة اجمالية
      </Button>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <PDFViewer style={styles.container}>
            <Document >
              <Page size="A5" style={styles.page}>
                <Image src={ENTETE} style={styles.image} />
                <Tete />
                <Header />
                <Row design={"2000"} number={detils_Data?.TotalB2000} type={"ورق"} />
                <Row design={"1000"} number={detils_Data?.TotalB1000} type={"ورق"} />
                <Row design={"500"} number={detils_Data?.TotalB500} type={"ورق"} />
                <Row design={"200"} number={detils_Data?.TotalB200} type={"ورق"} />



                <Row design={"200"} number={detils_Data?.TotalC200} type={"معدن"} />
                <Row design={"100"} number={detils_Data?.TotalC100} type={"معدن"} />
                <Row design={"50"} number={detils_Data?.TotalC50} type={"معدن"} />
                <Row design={"20"} number={detils_Data?.TotalC20} type={"معدن"} />
                <Row design={"10"} number={detils_Data?.TotalC10} type={"معدن"} />
                <Row design={"5"} number={detils_Data?.TotalC5} type={"معدن"} />
                <Footer title={"مجموع المداخيل في الصندوق"} totalDetail={detils_Data?.Balance} />
              </Page>
              {/* 
                {pathbool ? <Footer title={"مجموع المداخيل في الصندوق"} totalDetail={printData?.TotalDetail} /> : <Footer title={"مجموع المصاريف في الصندوق"} totalDetail={printData?.TotalSpendingDetail} />}
               */}
                
            </Document>
          </PDFViewer>
          <Button sx={{ direction: "ltr", mt: "20px" }} onClick={handleClose} variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:delete:fill" />}>
            الغاء
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
