import supabase from "src/supabase.config";
import currencyFormatter from "currency-formatter"




export const get_all_rests = async (startDate, endDate) => {
    let { data: rests, error } = await supabase
        .from('rests')
        .select('*')
        // .gte('Date', startDate)
        // .lte('Date', endDate)
    if (rests)
        return rests
}


export const format_sold = (sold) => {
    return currencyFormatter.format(sold, {
        symbol: "دج",
        decimal: ",",
        thousand: ".",
        precision: 2,
        format: "%v %s", // %s is the symbol and %v is the value
    })
}





export const get_income=async(income_id)=>{

    let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select('*')
    .eq('id',income_id)
    if(incomes_coffre) return incomes_coffre
    if(error) console.log(error)
  
}