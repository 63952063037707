import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Delete, DeleteForever } from '@mui/icons-material';
import { delete_income } from './utils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: ' white',
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};

export default function DeleteForm({data}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const Delete_me=()=>{
  delete_income(data?.id).then(res=>{
    handleClose()
  })
  
  }
  return (
    <div>
      <Button onClick={handleOpen} sx={{width:'100px',color:"tomato"}}>
        <Delete/>مسح
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} dir="rtl">
          <Typography id="modal-modal-title" variant="h6" component="h2">
     مسح مدخول الى الخزينة
          </Typography>
          <Button onClick={Delete_me} variant="contained"sx={{marginLeft:10}}>
            مسح <DeleteForever/>
          </Button>
          <Button variant="outlined"onClick={()=>handleClose()}>
            الغاء
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
