import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import Tajwal from "../fonts/tajawal-arabic-400-normal.ttf"



Font.register({
    family: 'Tajwal',
    src: Tajwal

});
// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {

        height: '40px', width: "100%",
        display: "flex",
        flexDirection: 'row',
       


    },
    BText: {
        fontFamily: "Tajwal",
        fontSize: "14px",
        fontWeight: 'bold',

    },
    ColTete: {
        height: '40px', width: "50%",
        justifyContent:'center',
        alignItems:'center',
        display: "flex",
    }


});
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '-' + dd + '-' + yyyy+"        ";



 
var todayTime = new Date();
 
var time = todayTime.getHours() + ":" + todayTime.getMinutes() + ":" + todayTime.getSeconds();

 
 
const Tete = () => {
    return (
        <View style={styles.section}>

            <View style={styles.ColTete}>
                <Text style={styles.BText}> {time}    الساعة</Text>
            </View>
            <View style={styles.ColTete}>
                <Text style={styles.BText}> {today  }التاريخ</Text>
            </View>

        </View>
    )
}

export default Tete