import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { SimpleGrid } from '@chakra-ui/react'
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// React
import {useState,useEffect} from "react"
// ----------------------------------------------------------------------

import supabase from 'src/supabase.config';
import { Box } from '@mui/system';
export default function DashboardApp() {
  const theme = useTheme();
const [Depots, setDepots] = useState([])

const [NetIFRI, setNetIFRI] = useState(0)
const [NetMMD, setNetMMD] = useState(0)
const [NetMMG, setNetMMG] = useState(0)
const [Total_income_IFRI, setTotalIFRI_income] = useState(0)
const [Total_income_MMD, setTotalMMD_income] = useState(0)
const [Total_income_MMG, setTotalMMG_income] = useState(0)

const [Total_spends_IFRI, setTotalIFRI_spends] = useState(0)
const [Total_spends_MMD, setTotalMMD_spends] = useState(0)
const [Total_spends_MMG, setTotalMMG_spends] = useState(0)

var IFRI_T_Spends=0
var MMG_T_Spends=0
var MDD_T_Spends=0

var IFRI_T_Income=0
var MMG_T_Income=0
var MDD_T_Income=0

const get_Depot=async()=>{
  let { data: caisse, error } = await supabase
  .from('caisse')
  .select('*')
  if (caisse) {
 
    setDepots(caisse)
  }
}

const get_spends=async()=>{
  
  let { data: spends_coffre, error } = await supabase
  .from('spends_coffre')
  .select(`
sold,caisse

`)
if(spends_coffre){
  
  for(let i=0;i<spends_coffre.length;i++){
    if(spends_coffre[i].caisse==1){
    
      IFRI_T_Spends=spends_coffre[i].sold+IFRI_T_Spends
    }
    if(spends_coffre[i].caisse==3){
      MMG_T_Spends=spends_coffre[i].sold+MMG_T_Spends
    }
    if(spends_coffre[i].caisse==2){
      MDD_T_Spends=spends_coffre[i].sold+MDD_T_Spends
    }
  }
 
  setTotalIFRI_spends(IFRI_T_Spends)
  setTotalMMD_spends(MDD_T_Spends)
  setTotalMMG_spends(MMG_T_Spends)

}
if(error){}
}




const get_incomes = async () => {
  let { data: incomes_coffre, error } = await supabase
    .from('incomes_coffre')
    .select(`
  sold,caisse

`)
  if(incomes_coffre){
for(let i=0;i<incomes_coffre.length;i++){
if(incomes_coffre[i].caisse==1){
 
  IFRI_T_Income= incomes_coffre[i].sold+IFRI_T_Income
}
if(incomes_coffre[i].caisse==3){
   
  MMG_T_Income= incomes_coffre[i].sold+MMG_T_Income
}
if(incomes_coffre[i].caisse==2){
  
  MDD_T_Income= incomes_coffre[i].sold+MDD_T_Income
}
}
setTotalIFRI_income(IFRI_T_Income)
setNetIFRI(IFRI_T_Income-IFRI_T_Spends)
setNetMMD(MDD_T_Income-MDD_T_Spends)
setNetMMG(Number(MMG_T_Income)-Number(MMG_T_Spends))
setTotalMMD_income(Number(MDD_T_Income))
setTotalMMG_income(Number(MMG_T_Income))
  }
  if(error){

  }
}
useEffect(() => {
  

  get_incomes()
  get_Depot()
  get_spends()
},[get_Depot,get_incomes,get_spends,IFRI_T_Spends,MMG_T_Spends,MDD_T_Spends,IFRI_T_Income,MMG_T_Income,MDD_T_Income]);



  return (
    <Page title="Dashboard" sx={{direction:"rtl"}}>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5,direction:"rtl" }}>
          احصاء الصناديق
        </Typography>
        <SimpleGrid columns={3} spacing={10}>


<AppWidgetSummary title={"IFRI"} caisse={1} total={(Total_income_IFRI)-(Total_spends_IFRI)}  income={Total_income_IFRI} color={"success"} spend={Total_spends_IFRI} icon={'ant-design:android-filled'} />

<AppWidgetSummary title={"MMG"} caisse={3} total={Number(Total_income_MMG)-Number(Total_spends_MMG)}  income={Total_income_MMG} color={"success"} spend={Total_spends_MMG} icon={'ant-design:android-filled'} />

<AppWidgetSummary title={"MDD"} caisse={2} total={Total_income_MMD-Total_spends_MMD}  income={Total_income_MMD} color={"success"} spend={Total_spends_MMD} icon={'ant-design:android-filled'} />

  
</SimpleGrid>
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <br></br>
            <AppWebsiteVisits
              title="الاحصائيات الشهرية للصناديق"
              subheader=" أكتر من الشهر الماضي(+43%)"
              chartLabels={[
                '01/01/2022',
                '02/01/2022',
                '03/01/2022',
                '04/01/2022',
                '05/01/2022',
                '06/01/2022',
                '07/01/2022',
                '08/01/2022',
                '09/01/2022',
                '10/01/2022',
                '11/01/2022',
              ]}
              chartData={[
                {
                  name: 'IFRI',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'MMD',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'MMG',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          {/* {
            Depots?.map(depot=>{
              return(
                <Grid key={depot.id} item xs={12} sm={6} md={3}>
                <AppWidgetSummary title={depot?.name} total={depot?.balance} icon={'ant-design:android-filled'} />
              </Grid>
              )
            })
          } */}
            {/* <Grid  item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={"IFRI"} total={Total_income_IFRI-Total_spends_IFRI} income={Total_income_IFRI} spend={Total_spends_IFRI} icon={'ant-design:android-filled'} />

            </Grid>
            <Grid  item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={"MMD"} total={Total_income_MMD-Total_spends_MMD}  income={Total_income_MMD} spend={Total_spends_MMD} icon={'ant-design:android-filled'} />

            </Grid>
            <Grid  item xs={12} sm={6} md={3}>
            <AppWidgetSummary title={"MMG"} total={Total_income_MMG-Total_spends_MMG}  income={Total_income_MMG} spend={Total_spends_MMG} icon={'ant-design:android-filled'} />

            </Grid> */}
            
             
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="MMG" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="MDD" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="IFRI" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
