import { Chip } from '@mui/material'
import React ,{useState,useEffect} from 'react'
import supabase from 'src/supabase.config'


const get_Coffre=async(coffre_id)=>{
    let { data: caisse, error } = await supabase
    .from('caisse')
    .select('name')
    .eq("id",coffre_id)
    if(caisse)return caisse[0].name
}



const CoffreUtil = ({ Util_type }) => {
    const [coffre, setcoffre] = useState("")
    var currentdate = new Date();

    var datetime = currentdate.getDate() + "-" + (currentdate.getMonth() + 1)
        + "-" + currentdate.getFullYear()

    var time = currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":" + currentdate.getSeconds();

useEffect(() => {
  get_Coffre(localStorage.getItem("coffre_id")).then(res=>{
    setcoffre(res)
  })

  
}, [])



    return (
        <>

            {
                (Util_type == "Coffre") ? <Chip sx={{ mb: 5 }} label={"الخزينة "+coffre} /> : (Util_type == "Date") ? <Chip sx={{ mb: 5 }} label={"التاريخ  "+datetime} /> : (Util_type == "Time") ? <Chip sx={{ mb: 5 }} label={" الساعة  " +time} /> : ""
            }
        </>
    )
}

export default CoffreUtil