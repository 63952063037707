import React from 'react'
import { useState, useEffect } from 'react'


// Material Ui
import { Grid, Box, TextField, Chip } from '@mui/material'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { newIncome, newTransfer } from "../../redux/slice"



import currencyFormatter from "currency-formatter"




const DetailsForm = () => {
  //Paper 
  // const [B2000, setB2000] = useState(0)
  // const [B1000, setB1000] = useState(0)
  // const [B500, setB500] = useState(0)
  // const [B200, setB200] = useState(0)

  //Coin
  // const [C200, setC200] = useState(0)
  // const [C100, setC100] = useState(0)
  // const [C50, setC50] = useState(0)
  // const [C20, setC20] = useState(0)
  // const [C10, setC10] = useState(0)
  // const [C5, setC5] = useState(0)

  // Total Paper and Coin
 
  const [paperSold, setpaperSold] = useState(0)
  const [coinSOld, setcoinSOld] = useState(0)
  

  const [TotalSold, setTotalSold] = useState(0)

  const dispatch = useDispatch()
  const Income_Data = useSelector((state) => state.caisse.new_income)
  
  const handlenewIncome = (e) => {
    switch (e.target.id) {

      case 'B2000':
        dispatch(newIncome({ key: 'B2000', value: Number(e.target.value) }))
        break;
      case 'B1000':
        dispatch(newIncome({ key: 'B1000', value:  Number(e.target.value) }))
        break;
      case 'B500':
        dispatch(newIncome({ key: 'B500', value: Number(e.target.value) }))
        break;
      case 'B200':
        dispatch(newIncome({ key: 'B200', value: Number(e.target.value) }))
        break;
      case 'C200':
        dispatch(newIncome({ key: 'C200', value:  Number(e.target.value) }))
        break;
      case 'C100':
        dispatch(newIncome({ key: 'C100', value:  Number(e.target.value) }))
        break;
      case 'C50':
        dispatch(newIncome({ key: 'C50', value:  Number(e.target.value) }))
        break;
      case 'C20':
        dispatch(newIncome({ key: 'C20', value: Number(e.target.value) }))
        break;
      case 'C10':
        dispatch(newIncome({ key: 'C10', value:  Number(e.target.value)}))
        break;
      case 'C5':
        dispatch(newIncome({ key: 'C5', value:  Number(e.target.value) }))
        break;
    }
    console.log(Income_Data)
  } 

 
    useEffect(() => {
      setTotalSold(Income_Data.B2000*2000+Income_Data.B1000*1000+Income_Data.B500*500+Income_Data.B200*200+Income_Data.C200*200+Income_Data.C100*100+Income_Data.C50*50+Income_Data.C20*20+Income_Data.C10*10+Income_Data.C5*5)
      setpaperSold(Income_Data.B2000*2000+Income_Data.B1000*1000+Income_Data.B500*500+Income_Data.B200*200)
      setcoinSOld(Income_Data.C200*200+Income_Data.C100*100+Income_Data.C50*50+Income_Data.C20*20+Income_Data.C10*10+Income_Data.C5*5)
    });

  return (
    <Box sx={{ width: '100%', height: '350px', padding: '40px' }}>
      <Box  sx={{display:"flex",width:'300px',height:'120px'}}>

     
      <Chip sx={{ mb: 4,ml:2 , backgroundColor: "yellowgreen", color: "white" }} label={"المبلغ الورقي  "+currencyFormatter.format(paperSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
                <Chip sx={{ mb: 4,ml:2, backgroundColor: "yellowgreen", color: "white",direction:'rtl' }} label={" المبلغ المعدني  "+currencyFormatter.format(coinSOld, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2,
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               <Chip sx={{ mb: 4,ml:2  ,backgroundColor: "yellowgreen", color: "white" }} label={"   المبلغ الاجمالي  "+currencyFormatter.format(TotalSold, {
                symbol: "دج",
                decimal: ",",
                thousand: ".",
                precision: 2, 
                format: "%v %s", // %s is the symbol and %v is the value
              })} />
               </Box>
     , 
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="B2000" label="  2000 دج" value={Income_Data.B2000} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B1000" label="1000 دج" value={Income_Data.B1000} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B500" label="500 دج" value={Income_Data.B500} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="B200" label="200 دج" value={Income_Data.B200} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>

      </Grid>
      <br></br>
     
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C200" label="200 دج" value={Income_Data.C200} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C100" label="100 دج" value={Income_Data.C100} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>

        <Grid item xs={3}>
          <TextField id="C50" label="50 دج" value={Income_Data.C50} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C20" label="20 دج"  value={Income_Data.C20}    variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>

        <Grid item xs={3}>
          <TextField id="C10" label="10 دج"   value={Income_Data.C10}  variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>
        <Grid item xs={3}>
          <TextField id="C5" label="5 دج" value={Income_Data.C5} variant="outlined" onChange={(e) => handlenewIncome(e)} />

        </Grid>


      </Grid>
    </Box>
  )
}

export default DetailsForm