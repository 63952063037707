import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import supabase from "../../supabase.config"
// mock



// Resuable Components
import Menuaction from "./menuaction"
import MenuPopover from 'src/components/MenuPopover';
import IncomeTable from "./spendTable"
import NewModal from "./newModal"
import SearchFilter from "./searchFIlter"


export default function Change_coffre() {

const getcustomer=async()=>{
  const { data, error } = await supabase
  .from('customer')
  .select().then((value) => {
    console.log(value.data);
    // expected output: "Success!"
  });
  
}
 
 
  return (
    <Page title="Changement Coffre">
      <Container sx={{direction:"rtl"}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{color:'tomato'}}>
            المصاريف
          </Typography>
       

          <NewModal/>
        </Stack>
<SearchFilter/>
<Box sx={{width:'100%',height:'50px' ,display:'flex',justifyContent:"space-between"}}>
<Chip sx={{backgroundColor:"#6495ED",color:"white"}} label='عدد العمليات 32'/>

<Chip sx={{backgroundColor:"tomato",color:"white"}} label='   مبلغ المصاريف 32'/>


</Box>
        <Card>
         
          <Scrollbar>
           <IncomeTable/>
          </Scrollbar>

        
        </Card>
      </Container>
    </Page>
  );
}
